import React, { useState } from 'react'
import Storage from 'util/store/Storage'
import Axios from 'axios'
import Server from 'util/Server'
import { useSessionStorage } from 'hooks/useSessionStorage'
import { Card, Image, Space, Button } from 'antd'
import ModalUserInfo from './ModalUserInfo'
const { Meta } = Card

const UserInfo = ({ closeDrawer }) => {
  const [userInfoModalOpen, setUserInfoModalOpen] = useState(false)
  const { clearSessionStorage } = useSessionStorage()

  const handleCerrarSesion = () => {
    const ntoken = sessionStorage.getItem('token')
    Axios.delete(Server.host + Server.puertoApp + '/api/v1/', { headers: { Authorization: ntoken } })
      .then(response => {})
      .catch(function (error) {
        console.log(error)
      })
    clearSessionStorage()
    window.location.reload()
  }

  let { usuario } = Storage.getState()

  return (
    <>
      <ModalUserInfo open={userInfoModalOpen} setOpen={setUserInfoModalOpen} />
      <Card
        style={{ width: 300, marginTop: 12, backgroundColor: '#f4f4f4' }}
        loading={false}
      >
        <Meta
          avatar={<Image src={`${Server.hostMultimedia}assets/img/usericon.png`} />}
          title={usuario?.usuario || 'No info'}
          description={
            <Space direction="vertical">
              <a
                onClick={() => {
                  closeDrawer();
                  setUserInfoModalOpen(true);
                }}
                style={{
                  color: '#DF1A0A'
                }}
              >
                Ver Perfil
              </a>
              <a
                onClick={handleCerrarSesion}
                style={{
                  color: '#DF1A0A'
                }}
              >
                Cerrar sesión
              </a>
            </Space>
          }
        />
      </Card>
    </>
  )
}

export default UserInfo
