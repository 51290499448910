import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import UserInfo from 'components/UserInfo'
import { toggleCollapsedNav } from 'actions/Setting'
import { Drawer, Menu, message, Dropdown, Typography } from 'antd'
import Storage from 'util/store/Storage'
import { RenderIcon, RenderItemMenu, styleIcons, styleIconsChildre } from './utilsIconsMenu.jsx'
import { BarChartOutlined, CaretRightOutlined, DashboardOutlined, StarFilled, StarOutlined } from '@ant-design/icons'
import SocketIO from 'socket.io-client'
import Server from 'util/Server.js'
import { saveAndGetFavorites } from 'services/Navbar/'
import useSocket from '../../hooks/useSocket.tsx'
import { descargarExcel } from 'components/SmartDataTable/services/services.ts'
import { saveAs } from 'file-saver'
import { obtenerPermisosModulos } from 'util/funciones.js'
import './index.css'

const socket = SocketIO.connect(Server.url_socket + Server.puertoEquipo, {
    transports: ['websocket']
})

const SideBar = () => {
    const itemFav = useRef(null)
    const [connected, setConnected] = useState(socket.connected)
    const dispatch = useDispatch()
    const { navCollapsed } = useSelector(({ settings }) => settings)

    // const socketReporteria = useSocket(Server.host + Server.puertoMovimiento)

    const [MenuItems, setMenuItems] = useState([])
    const [OpenKeyMenu, setOpenKeyMenu] = useState([])
    const [modulosFavoritos, setModulosFavoritos] = useState([])
    const ModulosPadresId = null
    const { usuario, actualizarvista } = Storage.getState()

    const [buffer, setBuffer] = useState(null)

    useEffect(() => {
        if (buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'reporteVentasPorEstacion.xlsx')
            document.body.appendChild(link)
            link.click()
            link.parentNode && link.parentNode.removeChild(link)
        }
    }, [buffer])
    const guardarFav = async (modulo, isFavorito) => {
        try {
            const { usuario, actualizarvista } = Storage.getState()
            const body = {
                link: modulo.link,
                identificadorPersona: usuario.p_id,
                descripcion: modulo.descripcion,
                extra: isFavorito ? 'D' : 'I'
            }
            const data = await saveAndGetFavorites(body)
            Storage.dispatch({
                type: 'ACTUALIZACION_DATOS',
                actualizarvista: !actualizarvista
            })
        } catch (error) {
            console.log('error')
        }
    }

    const obtenerModulosFavoritos = async () => {
        try {
            const body = {
                identificadorPersona: usuario.p_id,
                extra: 'G'
            }
            const peticion = await saveAndGetFavorites(body)
            setModulosFavoritos(peticion.data.map(modulos => modulos.link))
            return peticion.data.map(modulos => modulos.link)
        } catch (error) {
            setModulosFavoritos([])
            return []
        }
    }

    const modelarMenu = async () => {
        const modulosFavoritos2 = await obtenerModulosFavoritos()
        if (!usuario.modulos) return

        const hasConciliador = obtenerPermisosModulos(usuario,'CONCILIACIÓN PDV').length > 0
        const ModulosPadres = usuario.modulos.filter(item => item.modulos_id === ModulosPadresId)
        const ModulosMenuPadresHijos = ModulosPadres.map(item => {
            const ModulosHijos = usuario.modulos.filter(item2 => item2.modulos_id === item.id)
            return { ...item, ModulosHijos }
        })

        if (!ModulosMenuPadresHijos) return
        let itemwithoutdashboard = ModulosMenuPadresHijos.filter(item => item.descripcion !== 'DASHBOARD')
        const ItemsMenu = itemwithoutdashboard.map(item => {
            const children = item.ModulosHijos.map(item2 => {
                const esFavorito = modulosFavoritos2?.includes(item2?.link)
                return RenderItemMenu(
                    <div>
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        key: '1',
                                        label: (
                                            <a onClick={() => guardarFav(item2, esFavorito)}>{`${
                                                esFavorito ? 'Remover de ' : 'Añadir a '
                                            } favoritos`}</a>
                                        )
                                    }
                                ]
                            }}
                            trigger="contextMenu"
                        >
                            <Link
                                to={item2?.link}
                                style={{
                                    whiteSpace: 'break-spaces',
                                    lineHeight: 'initial'
                                }}
                            >
                                <div ><Typography style={{fontWeight:'bold'}}>{item2.descripcion}</Typography></div>
                            </Link>
                        </Dropdown>
                    </div>,
                    item2.id,
                    esFavorito ? (
                        <StarFilled onClick={() => guardarFav(item2, esFavorito)} style={styleIconsChildre} />
                    ) : (
                        <StarOutlined onClick={() => guardarFav(item2, esFavorito)} style={styleIconsChildre} />
                    ),
                    null,
                    item2.tipo
                )
            })

            return RenderItemMenu(<Typography style={{fontWeight:'bold'}}>{item.descripcion}</Typography>, item.id, RenderIcon(item?.icono), children, item.tipo)
        })
        const ItemsWithChilds = ItemsMenu.filter(item => item.children.length > 0)
        const ItemsWithChildsWithoutDash = [
            RenderItemMenu(
                <Link to={'/app/dashboard'}><Typography style={{fontWeight:'bold'}}>DASHBOARD</Typography></Link>,
                'DashBoard',
                <DashboardOutlined style={styleIcons} />,
                null,
                'DashBoard'
            ),hasConciliador && RenderItemMenu(
                <Link to={'/app/sap/conciliacion-pdv'}><Typography style={{fontWeight:'bold'}}>CONCILIADOR EDS</Typography></Link>,
                'conciliacion-pdv',
                <BarChartOutlined style={styleIcons}/>,
                null,
                'Conciliacion-pdv',
            ),
            ...ItemsWithChilds
        ]

        setMenuItems(ItemsWithChildsWithoutDash) 
    }
    useEffect(() => {
        obtenerModulosFavoritos()
    }, [actualizarvista])

    useEffect(() => {
        socket.connect()
        socket.on('connect', () => {
            setConnected(true)
        })
        socket.on('connect_error', err => {})
        socket.on('message', data => {
            if (parseInt(usuario.empresas_id) == data.empresa) {
                //NOTE: Las notificaciones deberían solo ser vistas por el administrador de la empresa
                const contenidoMensaje = (
                    <>
                        <b>{data.titulo ?? ''}</b>
                        <p>{data.texto ?? ''}</p>
                    </>
                )

                Storage.dispatch({
                    type: 'NOTIFICACIONES_FE',
                    notificacionesFE: { factura: data.consecutivo }
                })

                if (data.tipo == 200) {
                    message.info(contenidoMensaje, 8)
                }
                if (data.tipo == 400) {
                    message.error(contenidoMensaje, 8)
                }
            }
        })
       /*  if (socketReporteria != null) {
            try {
                socketReporteria.connect()


                socketReporteria.emit('user', {
                    user: usuario.usuario
                })
                socketReporteria.on('connect_error', err => {
                    console.log('error', err)
                })
                setInterval(() => {
                    socketReporteria.emit('ping', {
                        date: new Date()
                    })
                }, 5000)
                socketReporteria.on('pong', data => {})
                socketReporteria.on('export_excel', async data => {
                    const file = new Blob([data.buffer], { type: 'application/vnd.ms-excel' })
                    saveAs(file, data.fileName)
                    notification.success({
                        message: '¡Listo!',
                        description: 'Tu reporte se ha descargado correctamente',
                        placement: 'bottomRight',
                        duration: 3
                    })
                })

                socketReporteria.on('export_csv', async data => {
                    let myHeaders = new Headers()
                    myHeaders.append('Content-Type', 'application/json')
                    let raw = JSON.stringify(data)
                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw
                    }
                    fetch(`${Server.host}${Server.puertoMovimiento}/api/v2/descargar_reportes`, requestOptions)
                        .then(response => response.blob())
                        .then(buffer => {

                            const blob = new Blob([buffer], { type: 'text/csv' });
                            const url = URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.download = data.fileName;
                            document.body.appendChild(link);
                            link.click();
                            notification.success({
                                message: '¡Listo!',
                                description: 'Tu reporte se ha descargado correctamente',
                                placement: 'bottomRight',
                                duration: 3
                            })
                        })
                        .catch(error => console.log('error', error))
                })

                socketReporteria.on('notificacion',(data)=>{
                    notification.info({
                        message: 'Información general',
                        description: data.mensaje,
                        placement: 'bottomRight',
                        duration: 4
                    })
                })

                socketReporteria.on('export_pdf', async data => {
                    const file = new Blob([data.buffer], { type: 'application/pdf' })
                    saveAs(file, data.fileName)
                    notification.success({
                        message: '¡Listo!',
                        description: 'Tu reporte se ha descargado correctamente',
                        placement: 'bottomRight',
                        duration: 3
                    })
                })
            } catch (error) {
                console.log('error', error)
            }
        } */

        modelarMenu()
    }, [usuario, actualizarvista/* , socketReporteria */])

    const onToggleCollapsedNav = e => {
        const val = !navCollapsed
        dispatch(toggleCollapsedNav(val))
    }

    return (
        <Drawer
            title={<UserInfo closeDrawer={onToggleCollapsedNav} />}
            placement={'left'}
            closable={false}
            onClose={onToggleCollapsedNav}
            open={navCollapsed}
        >
            <Menu
            className='main-menu-terpel'
                onOpenChange={keys => {
                    if (keys.length > 0) {
                        setOpenKeyMenu(keys.slice(-1))
                        return
                    }
                    setOpenKeyMenu([])
                }}
                openKeys={OpenKeyMenu}
                multiple={false}
                mode="inline"
                items={MenuItems}
            />
        </Drawer>
    )
}

export default withRouter(SideBar)
