import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'

import Loader from 'components/Loader'

const CategoriasSubCategorias = lazy(async () => import('./categoriasSubCategorias/CategoriasSubCategorias'));

export default function Catalogo({ match }: any) {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<Loader />}>
              <Route  path={`${match.url}/categoria_subcategoria`} component={CategoriasSubCategorias}/>
        </Suspense>
      </Switch>
    </div>
  )
}
