import Axios from 'axios'
import server from 'util/Server'
import { sessionCaducada } from 'util/funciones'
import { FacturacionEstacion, MotivoAnulacion, TipoReferencia } from 'interfaces/FacturacionElectronica'
import { IMensajesFEPos } from 'app/vistas/Configuracion/ConfiguracionVentas/components/MensajesFE';
import Swal from 'sweetalert2';
import { ApiError, ApiResponse, IData, IFormValues } from 'app/vistas/Configuracion/ConfiguracionVentas/utils/FormularioObligacionFiscal.utils';


export const getMotivoAnulacionFacturacionElectronica = async () => {
    return new Promise<MotivoAnulacion[]>(function (resolve, reject) {
        Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/motivos-anulacion`, {
            headers: { Authorization: server.obtenerToken() }
        })
            .then(res => {
                if (res.status === server.responseOK) {
                    const datos: MotivoAnulacion[] = res.data.motivos
                    resolve(datos)
                } else if (res.status === server.responseSesionCaducada) {
                    const datos: MotivoAnulacion[] = []
                    resolve(datos)
                    sessionCaducada()
                }
            })
            .catch(err => {
                console.error(err)
                reject(err)
            })
    })
}

export const getTipoReferenciaFacturaElectronica = async () => {
    return new Promise<TipoReferencia[]>(function (resolve, reject) {
        Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/tipos-referencia`, {
            headers: { Authorization: server.obtenerToken() }
        })
            .then(res => {
                if (res.status === server.responseOK) {
                    const datos: TipoReferencia[] = res.data.tipos
                    resolve(datos)
                } else if (res.status === server.responseSesionCaducada) {
                    const datos: TipoReferencia[] = []
                    resolve(datos)
                    sessionCaducada()
                }
            })
            .catch(err => {
                console.error(err)
                reject(err)
            })
    })
}

export const asignarResponsablidadFiscalEstacion = async (estacionId:number, obligacionesFiscales:string[]) => {
    return Axios.post(`${server.host}${server.puertoLazoLite}/parametrizacion/${estacionId}/obligaciones-fiscales`,obligacionesFiscales, {
            headers: { Authorization: server.obtenerToken() }
        })
}
export const obtenerResponsablidadFiscalEstacion = async (estacionId:number) => {
    return Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/${estacionId}/obligaciones-fiscales`, {
            headers: { Authorization: server.obtenerToken() }
        })

}

export const getEmpresasFacturaElectronicaObligatoria = async (estaciones: number[]) => {
    return new Promise<FacturacionEstacion[]>(function (resolve, reject) {
        Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/obligatoriedad-fe/${estaciones.join(',')}`, {
            headers: { Authorization: server.obtenerToken() }
        })
            .then(res => {
                if (res.status === server.responseOK) {
                    const datos: FacturacionEstacion[] = res.data.configuraciones
                    const estacionesID:any[] = []
                    let filter :any={}
                    const estacionSinRepetir: FacturacionEstacion[] = datos.filter((eds)=>{
                        if(filter[eds.estacion_id])return false
                        filter[eds.estacion_id]=true
                        return true
                    })

                    resolve(estacionSinRepetir)
                } else if (res.status === server.responseSesionCaducada) {
                    const datos: FacturacionEstacion[] = []
                }
            })
            .catch(err => {
                console.error(err)
                reject(err)
            })
    })
}

export const getMontoMinimoFe = async () => {
    return new Promise<any>(function (resolve, reject) {
        Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/monto-minimo`, {
            headers: { Authorization: server.obtenerToken() }
        })
            .then(res => {
                if (res.status === server.responseOK) {
                    resolve(res.data)
                } else if (res.status === server.responseSesionCaducada) {
                    const datos: TipoReferencia[] = []
                    resolve(datos)
                    sessionCaducada()
                }
            })
            .catch(err => {
                console.error(err)
                reject(err)
            })
    })
}

export const guardarMotivosAnulacionFacturaElectronica = async (motivos: MotivoAnulacion[]) => {
    return Axios.post(`${server.host}${server.puertoLazoLite}/parametrizacion/motivos-anulacion`, motivos, {
        headers: { Authorization: server.obtenerToken() }
    })
}

export const guardarTipoReferenciaFacturaElectronica = async (motivos: TipoReferencia[]) => {
    return Axios.post(`${server.host}${server.puertoLazoLite}/parametrizacion/tipos-referencia`, motivos, {
        headers: { Authorization: server.obtenerToken() }
    })
}

export const guardarMontoMinimoFe = async (monto: number) => {
    return Axios.post(
        `${server.host}${server.puertoLazoLite}/parametrizacion/monto-minimo`,
        { monto_minimo: monto },
        { headers: { Authorization: server.obtenerToken() } }
    )
}

export const guardarFacturacionElectronicaObligatoria = async (configuracion:{estaciones: FacturacionEstacion[], usuario:string}) => {

    return Axios.post(`${server.host}${server.puertoLazoLite}/parametrizacion/obligatoriedad-fe`, configuracion, {
        headers: { Authorization: server.obtenerToken() }
    })
}

export const anularFacturaElectronica = (venta: string, codigo: number) => {
    const body = {
        venta: { id: venta },
        concepto: codigo,
        anulacion_web: true
    }
    return Axios.post(`${server.host}${server.puertoLazoLite}/movimientos/anular_venta`, body, {
        headers: { Authorization: server.obtenerToken() }
    })
}

export const getVentasEFAnuladas = async (data: any) => {
    return Axios.post(`${server.host}${server.puertoCentury}/api/movimiento/fe-anuladas`, data, {
        headers: { Authorization: server.obtenerToken() }
    })
}
export const actualizarEstadoFacturaElectronica = async (facturaId: number) => {
    return Axios.post(
        `${server.host}${server.puertoLazoLite}/guru.facturacion/ActualizarEstadoFactura/${facturaId}`,
        {},
        { headers: { Authorization: server.obtenerToken() } }
    )

}
export const reprocesarFacturaElectronica = async (facturaId: number) => {
    return Axios.get(
        `${server.host}${server.puertoLazoLite}/guru.facturacion/ReprocesarDatosDian/${facturaId}`,
        { headers: { Authorization: server.obtenerToken() } }
    )
}
export const reenviarFacturacionElectronica = async (id:number) => {
   return Axios.get(
        `${server.host}${server.puertoLazoLite}/guru.facturacion/ReenviarDatosDian/${id}`,
        { headers: { Authorization: server.obtenerToken() } }
    )
}

export const regenerarFacturaElectronica = async (facturaId: number, body: {empresas:any[]}) => {
    return Axios.post(
        `${server.host}${server.puertoLazoLite}/guru.facturacion/RegenerarFactura/${facturaId}`,body,
        { headers: { Authorization: server.obtenerToken() } }
    )
}

export const guardarMensajesFEPos = async (data: IMensajesFEPos) => {
    return Axios.post(`${server.host}${server.puertoLazoLite}/parametrizacion/mensajes-pos`, data, { headers: { Authorization: server.obtenerToken() } })
}
export const obtenerMensajesFEPos = async () => {
    return Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/mensajes-pos`, { headers: { Authorization: server.obtenerToken() } })
}

export const obtenerTiempoMaxDatosClientes = async () => {
    try {
        let data: any = await Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/tiempo-maximo-datos-cliente`)
        return data.data.tiempo_maximo
    } catch (error) {

        return null
    }
}
export const guardarTiempoMaxDatosClientes = async (data: any) => {
    return Axios.post(`${server.host}${server.puertoLazoLite}/parametrizacion/tiempo-maximo-datos-cliente`, data, { headers: { Authorization: server.obtenerToken() } })
}

export const obtenerTiempoReenvioNotaCredito = async () => {

    try {
        let datos: any = await Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/tiempo-reenvio-nota-credito`)
        return datos.data.tiempo_reenvio_nota_credito
    } catch (error) {
        return null
    }

}

export const obtenerTiempoReenvioFacturaEspera = async () => {
    try {
        let datos: any = await Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/tiempo-reenvio-facturas-espera`)
        return datos.data.tiempo_reenvio
    } catch (error) {
        return null
    }
}

export const obtenerTiempoReenvioFacturaEsperaAll = async () => {
    try {
        let datos: any = await Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/tiempo-reenvio-facturas-espera-all`)
        return datos.data.tiempo_reenvio
    } catch (error) {
        return null
    }
}

export const guardarTiempoReenvioNotaCredito = async (data: any) => {
    return Axios.post(`${server.host}${server.puertoLazoLite}/parametrizacion/tiempo-reenvio-nota-credito`, data, { headers: { Authorization: server.obtenerToken() } })
}

export const guardarTiempoReenvioFactura = async (data: any) => {
    return Axios.post(`${server.host}${server.puertoLazoLite}/parametrizacion/tiempo-reenvio-facturas-espera`, data, { headers: { Authorization: server.obtenerToken() } })
}

/**
 * Configurar tiempo para el cron que reenvirá las facturas en espera
 */
export const guardarTiempoReenvioFacturasEspera = async (data: any) => {
    return Axios.post(`${server.host}${server.puertoLazoLite}/parametrizacion/tiempo-reenvio-facturas-espera-all`, data, { headers: { Authorization: server.obtenerToken() } })
}

export const guardarFacturacionPorDefecto = async (data: any, mensaje : string) =>{
    const errores: any[] = []
        Swal.fire({
            title: 'Cargando..',
            text: mensaje,
            showConfirmButton:false
          })
        for (const estacion of data.estacion) {
            try {
                delete estacion.token
                await Axios.post(`${server.host}${server.puertoLazoLite}/parametrizacion/save/parametros/FE`, {...data , estacion : [estacion]},{ headers: { Authorization: server.obtenerToken() } } )
            } catch (error) {
                errores.push(estacion.estacion_id)
            }
        }
        Swal.close()

    return errores
}

export const obtenerFacturacionPorDefecto = async (idEstacion:number | string) =>{
    return Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/parametros/${idEstacion}`)
}


export const guardarFacturacionPorDefectoRemision = async (data: any, mensaje : string) =>{
    const errores: any[] = []
        Swal.fire({
            title: 'Cargando..',
            text: mensaje,
            showConfirmButton:false
          })
        for (const estacion of data.estacion) {
            try {
                delete estacion.token
                await Axios.post(`${server.host}${server.puertoLazoLite}/parametrizacion/save/parametros/REMISION`, {...data , estacion : [estacion]},{ headers: { Authorization: server.obtenerToken() } } )
            } catch (error) {
                errores.push(estacion.estacion_id)
            }
        }
        Swal.close()

    return errores
}

export const guardarObservacionesFacturacion = async (data: Omit<IFormValues,'responsabilidadFiscal' | 'id' >) => {
    return Axios.post(`${server.host}${server.puertoLazoLite}/parametrizacion/notas/facturacion`, data, { headers: { Authorization: server.obtenerToken() } })
}
export const actualizarObservacionesFacturacion = async (data: Partial<IFormValues>) => {
    return Axios.put(`${server.host}${server.puertoLazoLite}/parametrizacion/notas/facturacion/${data.id}`, data, { headers: { Authorization: server.obtenerToken() } })
}
export const obtenerObservacionesFacturacionByEDS = async (id: string | number): Promise<ApiResponse | ApiError> => {
try {
    const data = await Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/notas/facturacion/${id}`, { headers: { Authorization: server.obtenerToken() } })
    return data
} catch (error:any) {
    return {
        message: error.message,
        status: error.response.status,
        data:{} as IData
    }
}
}

export const obtenerTipoNegocios = async () =>{
    return Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/getTipoNegocio`, { headers: { Authorization: server.obtenerToken() } })
}
export const obtenerFormatosFE = async () =>{
    return Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/getFormatos`, { headers: { Authorization: server.obtenerToken() } })
}
export const obtenerFormatosEDS = async (id:number) =>{
    return Axios.get(`${server.host}${server.puertoLazoLite}/parametrizacion/listarFormatosEds/${id}`, { headers: { Authorization: server.obtenerToken() } })
}
export const guardarFormatos = async (body:any) => {
    return Axios.post(`${server.host}${server.puertoLazoLite}/parametrizacion/formatos/eds`,body, { headers: { Authorization: server.obtenerToken() } })
}