import React, { useEffect, useState } from 'react'
import { getCityByStateServices, getCountriesServices, getStateByCountryServices } from 'services/selectServices/services'
import { parseToSelectOption } from 'util/funcionesNews'

const useLocation = ():any => {
    const [countries, setCountries] = useState<any[]>([])
    const [states, setStates] = useState<any[]>([])
    const [cities, setCities] = useState<any[]>([])
    const [formLocation, setFormLocation] = useState<{
        country: number | null,
        state: number | null,
        city: number | null,
    }>({
        country: null,
        state: null,
        city: null
    })
    const [loaders, setLoaders] = useState({
        loaderCountry: false,
        loaderState: false,
        loaderCity: false,

    })

    const handlerLoaders = (name: 'loaderCountry' | 'loaderState' | 'loaderCity', value: boolean) => {
        setLoaders((prev) => ({ ...prev, [name]: value }))
    }

    const handlerForm = (name: 'country' | 'state' | 'city', value: number | null) => {
        setFormLocation((prev) => ({ ...prev, [name]: value }))
    }


    useEffect(() => {
        const getCountries = async () => {
            handlerLoaders('loaderCountry', true)
            const data = await getCountriesServices()
            setCountries(parseToSelectOption(data,'nombre_pais','id_paises'))
            handlerLoaders('loaderCountry', false)

        }
        getCountries()
    }, [])



    useEffect(() => {
        if (formLocation.country) {
            const getStates = async () => {
                handlerLoaders('loaderState', true)
                const data = await getStateByCountryServices(formLocation.country!)
                setStates(parseToSelectOption(data,'nombre_departamento','id_departamento'))
                handlerLoaders('loaderState', false)
            }
            getStates()
        }
        setCities([])
        handlerForm('city', null)
        handlerForm('state', null)
    }, [formLocation.country])


    useEffect(() => {
        if (formLocation.state) {
            const getCities = async () => {
                handlerLoaders('loaderCity', true)
                const data = await getCityByStateServices(formLocation.state!)
                setCities(parseToSelectOption(data,'nombre_departamento','id_provincia'))
                handlerLoaders('loaderCity', false)
            }
            getCities()
        }
        handlerForm('city', null)
    }, [formLocation.state])


    return (
        [
            countries,
            states,
            cities,
            formLocation,
            loaders,
            handlerForm
        ]
    )
}

export default useLocation