//@vendors
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import Sortable from 'sortablejs'
import moment from 'moment'

//@utils
import Storage from 'util/store/Storage'
import { formatearFecha, formatosFecha } from 'util/formatDate'

//@styles
import './Alerta.css'

//Graficas
import { ChartTopProductos } from './Charts'

import { BarraIndicadores, FiltrosOperation, PanelAlertas } from './Charts/Components'
// Hidhcharts
import Highcharts from 'highcharts'
import ChartModuleMore from 'highcharts/highcharts-more.js'
import HCSoldGauge from 'highcharts/modules/solid-gauge'
import Exporting from 'highcharts/modules/exporting'
import ExportingData from 'highcharts/modules/export-data'
import Tanque from 'components/Tanque'
import { getChartsData } from './ChartHelpers/Charts.services'
import ContainerDashBoard from 'components/ContainerDashBoard'
import ChartVentasDiarias from 'components/ChartVentasDiarias'
import ChartVentasUltimoMes from 'components/ChartVentasUltimoMes'
import ChartPresupuestoPDV from 'components/ChartPresupuestoPDV'
import { EmpresasContext } from './context/DashBoardContext'
import { getSelectEDS } from 'app/vistas/Reportes/servicios/serviciosSelect'
import { Select } from 'components/ui/Select'
import './NewDashboard.css'
import { objetoVacio } from 'util/utils'
import { determinateOperation } from './ChartHelpers/Chart.helper'
import { getDatosGraficaPDV } from './servicios'
import CharVentasCombustibles from 'components/CharVentasCombustibles/CharVentasCombustibles'
import { obtenerCierreDia } from '../Empresas/Eds/servicios'
import generateNumberRandom from 'util/security/utilSecurity'

ChartModuleMore(Highcharts)
HCSoldGauge(Highcharts)
Exporting(Highcharts)
ExportingData(Highcharts)

interface Estacion {
    id: number
    alias: string
    data: TanqueInterface[]
}

interface TanqueInterface {
    bodega: string
    codigo: string
    volumen_minimo: string
    volumen_maximo: string
    saldo: string
    descripcion: string
    nivelagua: number
    tipo: string
}

const Dashboard = (props: any, { match }: any): React.ReactElement => {
    const { empresasHijos, usuario } = Storage.getState()
    const [update, setUpdate] = useState(0)
    let status = true
    const [tanques, setTanques] = useState([] as Estacion[])
    const [VentasDiarias, setVentasDiarias] = useState([] as any[])
    const [VentasMensuales, setVentasMensuales] = useState([] as any[])
    const [presupuestoPdv, setPresupuestoPdv] = useState({} as any)
    const [selectionMes, setSelectionMes] = useState(1)
    const [selectionDia, setSelectionDia] = useState(1)
    const [selectionPDV] = useState(1)
    const [cierreDia, setcierreDia] = useState({} as any)
    const [filtrosTanques, setFiltrosTanques] = useState({
        tanquesBD: [] as any[],
        tanquesSeleccionado: {} as any
    })

    const colors = {
        CORRIENTE: 'red',
        EXTRA: 'blue',
        DIESEL: 'yellow',
        DEFAULT: 'cyan',
        GLP: 'gray',
        GNV: 'green'
    } as any
    const { empresa: empresaSeleccionada, setEmpresa } = useContext(EmpresasContext)

    useLayoutEffect(() => {
        setEmpresa(
            usuario.tipo === 5
                ? usuario.empresas_id
                : empresasHijos.split(',')[Math.floor(generateNumberRandom() * empresasHijos.split(',').length)]
        )
    }, [])

    const getTime = async () => {
        if (empresaSeleccionada != undefined && empresaSeleccionada!=null && !isNaN(parseInt(empresaSeleccionada))) {
            console.log(empresaSeleccionada)
            const dataTime = await obtenerCierreDia(empresaSeleccionada)
            setcierreDia(dataTime.data)
        } else {
            setcierreDia({})
        }
    }
    useEffect(() => {
        getTime()
    }, [empresaSeleccionada])

    const getDataGraficos = async () => {
        if (tanques.length > 0) {
            setTanques([] as Estacion[])
        }

        const DataTanques = (
            await getChartsData('ALERTA_TANQUES', { identificadorEstacion: empresaSeleccionada })
        ).filter(item => item.data.length !== 0)

        const getTanques = await getSelectEDS(DataTanques.map(item => item.id).join(','))
        setFiltrosTanques({
            ...filtrosTanques,
            tanquesBD: getTanques
        })

        setTanques(DataTanques)
    }


    useEffect(() => {
        draggable()
        getDataGraficos()


    }, [update, empresaSeleccionada])

    let intervalId: any = 0

    useEffect(() => {
        if (status) {
            startInterval()
        } else {
            stopInterval()
        }
        return () => {
            stopInterval()
        }
    }, [status, update])

    const startInterval = () => {
        intervalId = setInterval(() => {
            setUpdate(update + 1)
        }, 600000)
    }
    const stopInterval = () => {
        clearInterval(intervalId)
    }

    /*  Función para obtener los datos
            de la gráfica de TOTAL VENTAS MENSUALES  */
    const getDataGraficoVentasM = async () => {
        try {
            //Hora de toma de datos de 6:00AM de hoy a 6:00AM de mañana
            const hoy = moment()
            const mañana = hoy.clone().add(1, 'days')

            const { operacion, estadoMovimiento } = determinateOperation(selectionMes)

            const DataVentasMensuales = await getChartsData('VENTAS_TOTAL', {
                fecha_inicial: `${formatearFecha(hoy, formatosFecha.fechaServidor)} ${cierreDia?.data?.fecha_inicio ??
                    '06:00'}`,
                fecha_final: `${formatearFecha(mañana, formatosFecha.fechaServidor)} ${cierreDia?.data?.fecha_cierre ??
                    '06:00'}`,
                identificadorEstacion: empresaSeleccionada,
                tipoMovimiento: operacion,
                tipoEstadoMovimiento: estadoMovimiento,
                extra: 'M'
            })
            setVentasMensuales(DataVentasMensuales)
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getDataGraficoVentasM()
    }, [update, empresaSeleccionada, selectionMes])

    /*  Función para obtener los datos
            de la gráfica de TOTAL VENTAS DIARIA  */
    const getDataGraficoVentasD = async () => {
        try {
            const hoy = moment()
            const mañana = hoy.clone().add(1, 'days')

            const { operacion, estadoMovimiento } = determinateOperation(selectionDia)
            const DataVentasDiarias = await getChartsData('VENTAS_TOTAL', {
                //Hora de toma de datos de 6:00AM de hoy a 6:00AM de mañana
                fecha_inicial: `${formatearFecha(hoy, formatosFecha.fechaServidor)} ${cierreDia?.data?.fecha_inicio ??
                    '06:00'}`,
                fecha_final: `${formatearFecha(mañana, formatosFecha.fechaServidor)} ${cierreDia?.data?.fecha_cierre ??
                    '06:00'}`,
                identificadorEstacion: empresaSeleccionada,
                tipoMovimiento: operacion,
                tipoEstadoMovimiento: estadoMovimiento,
                extra: 'D'
            })
            setVentasDiarias(DataVentasDiarias)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getDataGraficoVentasD()
    }, [update, empresaSeleccionada, selectionDia])

    /* Funcion para obtener los datos para la
    grafica de presupuesto de PDV */
    const getDatosPdv = async () => {
        try {
            let operacion = []
            let meta: any[] = []
            switch (selectionPDV) {
                case 0:
                    operacion = ["'009"]
                    break
                case 1:
                    operacion = ["'017"]
                    break
                case 2:
                    operacion = ["'035"]
                    break
                case 3:
                default:
                    operacion = ["'009", "'017", "'035"]
                    break
            }
            const response = await getDatosGraficaPDV(empresaSeleccionada, operacion)

            response?.dias?.forEach((dia: any) => {
                meta.push(response.Meta_lograr)
            })
            let aux = 0
            setPresupuestoPdv({
                meta_lograr: meta,
                dias: response?.dias?.map((value: any, index: any) => {
                    if (index === 0) {
                        aux += value
                    }
                    if (index > 0) {
                        aux += value
                    }
                    return aux
                })
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getDatosPdv()
    }, [update, empresaSeleccionada, selectionPDV])

    /* Nuevo */
    const draggable = () => {
        const dashboard = document.getElementById('dashboard') as HTMLElement
        Sortable.create(dashboard, {
            animation: 350,
            swap: true,
            handle: '.handle',
            chosenClass: 'selected',
            dragClass: 'drag',
            filter: 'container_indicadores',
            fallbackOnBody: true,
            group: 'lista-dashboard',
            store: {
                set: sortable => {
                    const orden = sortable.toArray()
                    sessionStorage.setItem(sortable.options.group!.toString(), orden.join('|'))
                },
                get: sortable => {
                    const orden = sessionStorage.getItem(sortable.options.group!.toString())?.split('|')
                    return orden ? orden : []
                }
            }
        } as Sortable.Options)
    }

    const getTanque = () => {
        return tanques.length === 1 && tanques[0].data.length !== 0 ? (
            <div
                style={{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center',
                    overflowX: 'auto',
                    width: '100%',
                    justifyContent: 'space-between'
                }}
            >
                {tanques[0].data.map(item => (
                    <Tanque
                        data={{
                            contenido: Number(item.saldo),
                            capacidad: Number(item.volumen_maximo),
                            name: item.descripcion,
                            nivelAgua: item.nivelagua,
                            estado: 'A',
                            ultimaLectura: new Date(),
                            tipo: item.tipo,
                            bodega: item.bodega
                        }}
                        color={colors[item.tipo]}
                        key={item.descripcion}
                        size="xs"
                    />
                ))}
            </div>
        ) : (
            <div className="container__tanques_dashboard">
                <Select
                    id="filtros"
                    label="FILTRO EMPRESAS"
                    value={filtrosTanques.tanquesSeleccionado}
                    onChange={(value: any) => setFiltrosTanques({ ...filtrosTanques, tanquesSeleccionado: value })}
                    opciones={filtrosTanques.tanquesBD}
                    colMd={12}
                />
                <div className="container__tanque_dashboard">
                    {objetoVacio(filtrosTanques.tanquesSeleccionado)
                        ? tanques.map(item =>
                              item.data.map(data => (
                                  <Tanque
                                      data={{
                                          contenido: Number(data.saldo),
                                          capacidad: Number(data.volumen_maximo),
                                          name: data.descripcion,
                                          nivelAgua: data.nivelagua,
                                          estado: 'A',
                                          ultimaLectura: new Date(),
                                          tipo: data.tipo,
                                          bodega: data.bodega
                                      }}
                                      color={colors[data.tipo]}
                                      size="xs"
                                  />
                              ))
                          )
                        : tanques
                              .find(item => item.id === filtrosTanques.tanquesSeleccionado.value)
                              ?.data.map(data => (
                                  <Tanque
                                      data={{
                                          contenido: Number(data.saldo),
                                          capacidad: Number(data.volumen_maximo),
                                          name: data.descripcion,
                                          nivelAgua: data.nivelagua,
                                          estado: 'A',
                                          ultimaLectura: new Date(),
                                          tipo: data.tipo,
                                          bodega: data.bodega
                                      }}
                                      color={colors[data.tipo]}
                                      size="xs"
                                  />
                              ))}
                </div>
            </div>
        )
    }

    return (
        <div style={{ position: 'relative', border:'none' }}>
            {/* PANEL DE ALERTA */}
            {<PanelAlertas updateData={update} identificadorEstacion={empresaSeleccionada} />}
            <div className="container_dashboard" id="dashboard">
                {/* INDICADORES */}
                {empresaSeleccionada && (
                    <BarraIndicadores
                        id={0}
                        updateData={update}
                        identificadorEstacion={empresaSeleccionada}
                        width={1}
                    />
                )}
                {/* TOTAL VENTAS DIARIAS */}
                {usuario.modulos.find((item: any) => item.descripcion.includes('TOTAL VENTAS DIARIAS')) &&
                    VentasDiarias && (
                        <ContainerDashBoard
                            titulo={'TOTAL VENTAS DIARIAS'}
                            width={1}
                            id={1}
                            noData={VentasDiarias.length === 0}
                        >
                            {VentasDiarias.length !== 0 ? (
                                <div className="container__ventas-filtro">
                                    <ChartVentasDiarias
                                        updateData={update}
                                        data={[
                                            {
                                                name: 'Ventas',
                                                data: VentasDiarias.map(item => (item !== null ? +item : 0))
                                            }
                                        ]}
                                        type="line"
                                        color="red"
                                        length={1}
                                    />
                                    <FiltrosOperation
                                        onChange={(selection: number) => setSelectionDia(selection)}
                                        prevActive={selectionDia}
                                    />
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </ContainerDashBoard>
                    )}
                {/* TANQUES */}
                {usuario.modulos.find((item: any) => item.descripcion.includes('MEDIDAS TANQUES')) && (
                    <ContainerDashBoard
                        titulo={`MEDIDAS TANQUES ${formatearFecha(Date.now(), formatosFecha.fecha)}`}
                        width={1}
                        id={2}
                        noData={tanques.length === 0 ? true : false}
                    >
                        {tanques.length !== 0 ? getTanque() : <div></div>}
                    </ContainerDashBoard>
                )}
                {/* PRODUCTOS MAS VENDIDOS */}
                {usuario.modulos.find((item: any) => item.descripcion.includes('PRODUCTOS MÁS VENDIDOS')) && (
                    <ContainerDashBoard titulo={'PRODUCTOS MÁS VENDIDOS'} width={1} id={3}>
                        <div>
                            <ChartTopProductos updateData={update} identificadorEstacion={empresaSeleccionada} />
                        </div>
                    </ContainerDashBoard>
                )}
                {/* TOTAL VENTAS MENSUALES */}
                {usuario.modulos.find((item: any) => item.descripcion.includes('TOTAL VENTAS MENSUALES')) && (
                    <ContainerDashBoard
                        titulo={'TOTAL VENTAS MENSUALES'}
                        width={1}
                        id={4}
                        noData={VentasMensuales.length === 0 ? true : false}
                    >
                        {VentasMensuales.length !== 0 ? (
                            <div className="container__ventas-filtro">
                                <ChartVentasUltimoMes
                                    updateData={update}
                                    data={[
                                        {
                                            name: 'Ventas',
                                            data: VentasMensuales.map(item => (item !== null ? +item : 0))
                                        }
                                    ]}
                                    type="line"
                                    color="red"
                                    length={1}
                                />
                                <FiltrosOperation
                                    onChange={(selection: number) => setSelectionMes(selection)}
                                    prevActive={selectionMes}
                                />
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </ContainerDashBoard>
                )}
                {/* PRESUPUESTO DIARIO DEL PDV */}
                {usuario.modulos.find((item: any) => item.descripcion.includes('PRESUPUESTO DIARIO DEL PDV')) && (
                    <ContainerDashBoard titulo={'PRESUPUESTO DEL PDV'} width={1} id={5}>
                        <ChartPresupuestoPDV
                            updateData={update}
                            data={[
                                {
                                    name: 'Presupuesto esperado',
                                    data: presupuestoPdv.meta_lograr,
                                    color: 'orange'
                                },
                                {
                                    name: 'Presupuesto ejecutado',
                                    data: presupuestoPdv.dias,
                                    color: 'rgb(0, 119, 255)'
                                }
                            ]}
                            type="area"
                            length={1}
                        />
                    </ContainerDashBoard>
                )}
                {usuario.modulos.find((item: any) => item.descripcion.includes('VENTAS POR GALONAJE')) && (
                    <ContainerDashBoard titulo={'VOLUMEN DE VENTA'} width={1} id={6}>
                        <CharVentasCombustibles updateData={update} identificadorEstacion={empresaSeleccionada} />
                    </ContainerDashBoard>
                )}
            </div>
        </div>
    )
}

export default Dashboard
