// @vendors
import React, { useEffect } from 'react'
import Highcharts from 'highcharts'

// @styles
import './ChartVentasUltimoMes.css'

// @utils
import { setearMiles } from 'util/utils'
import { formatearFecha } from 'util/formatDate'
import moment from 'moment'

interface Props {
    data: any[]
    type: 'line' | 'area'
    color?: string
    length: 1 | 2 | 3
    updateData: number
}

const startDate = moment().subtract(5, 'months');

const ChartVentasUltimoMes = ({ data, type, color, length, updateData }: Props) => {
    const crearGrafica = () => {
        const options = {
            chart: {
                type: type,
                series: {
                    color: color
                }
            },
            title: {
                text: ''
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    formatter: function(this: any) {
                        return '$' + setearMiles(this.value ?? 0)
                    },
                    tooltip: {
                        formatter(this: Highcharts.TooltipFormatterContextObject) {
                            return ' $ ' + setearMiles(this.point.y ?? 0)
                        }
                    }
                }
            },
            xAxis: {
                type: 'datetime',
                title: {
                    style: { color: 'black', fontSize: '1.2rem' }
                },
                accessibility: {
                    rangeDescription: 'Range: 2010 to 2017'
                }
            },

            plotOptions: {
                series: {
                pointInterval: 2592000000, // One Month
                pointStart: startDate.valueOf(), // Convierte la fecha de inicio a milisegundos
                marker: {
                    enabled: false
                }
              }
            },
            series: data.map(item => ({
                name: item.name,
                color: color,
                data: item.data
            })),
            tooltip: {
                formatter(this: Highcharts.TooltipFormatterContextObject) {
                    return `$ ${setearMiles(this.point.y ?? 0)} <br/>  ${formatearFecha(new Date(this.point.x),'MM/YYYY')}`
                }
            }
        }
        Highcharts.chart('container__chartMensuales', (options as any) as Highcharts.Options)
    }

    useEffect(() => {
        crearGrafica()
    }, [updateData, data])

    return <div id="container__chartMensuales" className="col-md-12 mx-auto"></div>
}

export default ChartVentasUltimoMes

