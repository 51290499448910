import React from 'react'


export const rutaMonitorFacturacionElectronica = '/app/acciones/facturacion-electronica'

export const OPCIONES = [
    {
        value: 0,
        label: "0 PENDIENTE ENVIÓ"
    },
    {
        value: 3,
        label: "3 NO AUTORIZADO"
    },
    {
        label: '4 ERROR ANTES DE ENVIAR',
        value: 4
    },
    {
        value: 5,
        label: "5 RECIBIDA EN ESPERA DE AUTORIZACION",
    },
    {
        value: 6,
        label: "6 DEVUELTA EN RECEPCION"
    },
    {
        value: 7,
        label: "7 EN ESPERA DE RESPUESTA"
    },
    {
        value: 15,
        label: "15 DEVUELTO EDOC"
    },
    {
        value: 18,
        label: "18 DOCUMENTO NO ENCONTRADO"
    }
]

export const ColumnasModalAlertaFE = [
    {
        field: 'razon_social',
        header: 'ESTACION'
    },
    {
        field: 'factura',
        header: 'FACTURA'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'estado',
        header: 'ESTADO'
    },
    {
        field: 'nombre_cliente',
        header: 'CLIENTE'
    }
]

export const opcionesTipoNota = [
    {
        label: "CREDITO",
        value: 'C'
    },
    {
        label: "DEBITO",
        value: 'D'
    }
]

export const FEnoautorizadas = [
    {
        field: 'acciones',
        header: ''
    },
    {
        field: 'estacion',
        header: 'ESTACION'
    },
    {
        field: 'factura',
        header: 'FACTURA'
    },
    {
        field: 'estado',
        header: 'ESTADO FACT.'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'codigo_error',
        header: 'CODIGO ERROR'
    },
    {
        field: 'total_bruto',
        header: 'TOTAL BRUTO'
    },
    {
        field: 'total_impuesto',
        header: 'TOTAL IMPUESTO'
    },
    {
        field: 'total_descuento',
        header: 'TOTAL DESCUENTO'
    },
    {
        field: 'total_factura',
        header: 'TOTAL FACTURA'
    },
    {
        field: 'cufe',
        header: 'CUFE'
    },
    {
        field: 'tipo_documento',
        header: 'TIPO DOCUMENTO'
    },
    {
        field: 'numero_identificacion',
        header: 'NUM. IDENTIFICACION'
    },

    {
        field: 'cliente',
        header: 'CLIENTE'
    },
    {
        field: 'estado_desc',
        header: 'COMENTARIO'
    },
   
]


export const FEnoautorizadasPDF = [
    {
        field: 'estacion',
        header: 'ESTACION'
    },
    {
        field: 'factura',
        header: 'FACTURA'
    },
    {
        field: 'estado',
        header: 'ESTADO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'codigo_error',
        header: 'CODIGO ERROR'
    },
    {
        field: 'total_bruto',
        header: 'TOTAL BRUTO'
    },
    {
        field: 'total_impuesto',
        header: 'TOTAL IMPUESTO'
    },
    {
        field: 'total_descuento',
        header: 'TOTAL DESCUENTO'
    },
    {
        field: 'total_factura',
        header: 'TOTAL FACTURA'
    },
    {
        field: 'cufe',
        header: 'CUFE'
    },
    {
        field: 'tipo_documento',
        header: 'TIPO DOCUMENTO'
    },
    {
        field: 'numero_identificacion',
        header: 'NUM. IDENTIFICACION'
    },

    {
        field: 'cliente',
        header: 'CLIENTE'
    },
    {
        field: 'comentario',
        header: 'COMENTARIO'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    },
]

export const AnulacionFacturaElectronica = [
    {
        field: 'codigo',
        header: 'CODIGO'
    },


    {
        field: 'descripcion',
        header: 'DESCRIPCION'
    },


]

export const tipoDeReferenciaTb = [
    {
        field: 'codigo',
        header: 'CODIGO'
    },
    {
        field: 'tipo_nota',
        header: 'TIPO NOTA'
    },
    {
        field: 'descripcion',
        header: 'DESCRIPCION'
    },
]

export const tablaFacturaObligatoria = [
    {
        field: 'razon_social',
        header: 'EDS',
        style:{
            width:'300px',
            "text-align": 'left'
        }
    },
    {
        field: 'fe_habilitada',
        header: 'HABILITADA',
        style:{
            width:'100px'
        }
    },
    {
        field: 'fe_obligatoria',
        header: 'OBLIGATORIA',
        style:{
            width:'100px'
        }
    }, {
        field: 'remision',
        header: 'REMISIÓN',
        style:{
            width:'100px'
        }
    },
    {
        field: 'token',
        header: 'CLAVE',
        style:{
            width:'600px'
        }
    }

]

export const COLUMNAS_MENSAJES = [
    { field: 'descripcion', header: 'DESCRIPCIÓN' },
    { field: 'mensaje', header: 'MENSAJE' }
]

export const MENSAJES_EXITO = [
    { descripcion: 'Mensaje al enviarse la factura electronica correctamente', field: 'exitoEnvioFE' },
    { descripcion: 'Mensaje al convertir factura POS a electronica correctamente', field: 'exitoConversionFE' },
    { descripcion: 'Mensaje al realizar una nota credito exitosamente', field: 'exitoNotaCredito' },
]

export const MENSAJES_TIRILLA_FE = [
{descripcion:'Fecha validación para estados 5 o 7', field:'mensajeEstado5_7'},
{descripcion:'Fecha validación para estados 8 o 20', field:'mensajeEstado8_20'},
{descripcion:'Mensaje Extra 1', field:'mensajeExtra1'},
{descripcion:'Mensaje Extra 2', field:'mensajeExtra2'},

]

export const MENSAJES_ERROR = [
    { descripcion: 'Mensaje de error al enviarse la factura electronica', field: 'errorEnvioFE' },
    { descripcion: 'Mensaje de error al convertir a factura electronica', field: 'errorConversionFE' },
    { descripcion: 'Mensaje de error al realizar una nota credito', field: 'errorNotaCredito' },
    { descripcion: 'Mensaje sin conexion con proveedor tecnológico', field: 'errorConexionProveedor' },
    { descripcion: 'Mensaje sin conexion a internet', field: 'errorConexionInternet' },
]

export const MENSAJES_CONTINGENCIA = [
    { descripcion: 'Mensaje cuando el surtidor está fuera de servicio', field: 'contingenciaSurtidorFueraServicio' },
    { descripcion: 'Mensaje cuando el POS esta fuera de servicio', field: 'contingenciaPosFueraServicio' },
]

export const MENSAJES_IMPRESION = [
    { descripcion: 'Mensaje de comprobante de venta', field: 'impresionComprobante' },

]
export const TABLA_KARDEX_CONSOLIDADOS = [
    {
        field: "id",
        header: "ID"
    },
    {
        field: "descripcion",
        header: "DESCRIPCION"
    },
    {
        field: "entradas",
        header: "ENTRADAS"
    },
    {
        field: "salidas",
        header: "SALIDAS"
    },
]

export interface IFacturaMonitorElectronico {
    razon_social: string;
    total_bruto: number;
    impuesto_total: number;
    descuento_total: number;
    venta_total: number;
    numero_identificacion_cliente: string;
    nombre_cliente: string;
    codigo_tipo_documento: number;
    tipo_documento: string;
    alias: string;
    id: number;
    empresas_id: string;
    consecutivo: string;
    prefijo: string;
    fecha: Date;
    cufe: string;
    codigo_estado: number;
    estado: string;
    estado_desc: string;
    codigo_error: string;
    regenerando: boolean;
    checked: boolean;
}

export interface IPropsProcesosFactura {
    facturasSelected: number[];
    visibleParaNegocio: boolean;

    estadoFacturaActual: number;

    empresaConsultada: number
    onFinished: () => void
    permisos:string

    posContingencia:boolean

    enviandoFactura: React.Dispatch<React.SetStateAction<boolean>>

    facturas:IFacturaMonitorElectronico[]

}

export interface IFacturasProcesadas { facturaId: number, error: boolean }

type verboContinuo = 'Reenviando' | 'Reprocesando' | 'Actualizando' | 'Regenerando'

type verboFinalizado = 'actualizada(s)' | 'reenviada(s)' | 'reprocesanda(s)' | 'regenerada(s)'

type verboRaiz= 'actualizar' | 'regenerar' | 'reprocesar' | 'reenviar' 
export interface IVerbProceso {
    continuo:verboContinuo,
    finalizado:verboFinalizado
    raiz:verboRaiz
}
