import React, { useState, useEffect } from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Badge, Box, Button, Grid, LinearProgress, Paper, Popover, Popper, Tooltip, Typography } from '@material-ui/core'
import DataTableFilterDemo from 'components/Datatable'
import ProjectsList from 'components/dashboard/Intranet/ProjectsList'
import {
    alertaSecadoVirtual,
    obtenerJornadasAbiertas,
    obtenerOrdenCompra,
    obtenerConsecutivo,
    getAlertaDiaVenta,
    getVentasContingencia
} from './Alertas.services'
import '../../../Alerta.css'
import Filter from 'assets/images/dashboard/filter.svg'
import FilterEntiti from '../FilterEntity'
import { useSessionStorage } from 'hooks/useSessionStorage'
import { Delete } from '@material-ui/icons/'

import Storage from 'util/store/Storage'
import { COLUMNS_TABLE, getGlobalAlertsV2, setGlobalAlertsV2 } from './servicios.js'
import { formatearFecha, formatosFecha } from 'util/formatDate'
import { getFE } from 'app/vistas/Acciones/serviciosAll'
import ModalAlertaFacturaElectronica from './components/ModalAlertaFacturaElectronica'
import { ESTADOS_FACTURACION_ELECTRONICA } from 'app/vistas/Acciones/Constants'
import moment from 'moment'
import ModalAlertaVentasContingencia from './components/ModalAlertaVentasContingencia'


import './index.css'

import { SwalTerpel } from 'util/alerts/SwalTerpel'
import EnviarIcon from 'util/alerts/EnviarIcon'
import { getFacturasContigencia, getPosContingencia } from 'app/vistas/Dashboard/servicios'
import { FaInfoCircle } from 'react-icons/fa'
import { reprocesarFacturaElectronica } from 'services/FacturacionElectronica/FacturacionElectronica.service'
import { Drawer } from 'antd'
import { BellOutlined, CloseOutlined } from '@ant-design/icons'
import { Modal } from 'react-bootstrap'


interface IPosContingencia {
    alias: string,
    isla: number
}
export interface IDataFacturaContingencia {
    mensaje: string;
    fechaProceso: Date;
    facturas: Factura[];
}

export interface Factura {
    id: number;
    consecutivo: string;
    prefijo: string;
}

const PERFILESAUTORIZADOS = [
    'ADMINISTRADOR DE EDS BETA 3',
    'ADMINISTRADOR DE ESTACION',
    'SUPERADMINISTRADOR LAZO',
    'ADMINISTRADOR DE EDS BETA 4'

]
interface IFacturasReprocesadas {
    facturaId: number,
    error: boolean
}
const PanelAlertas = ({ updateData, identificadorEstacion }: any): React.ReactElement => {
    const [dataModal, setDataModal] = useState({} as any)
    const [jornadasAbiertas, setJornadasAbiertas] = useState([] as any)
    const [consecutivos, setConsecutivos] = useState([] as any)
    const [ordenesCompra, setOrdenesCompra] = useState([] as any)
    const [secadoVirtual, setSecadoVirtual] = useState([] as any)
    const [filterOpen, setFilterOpen] = useState(false)
    const [alertaDiaVenta, setAlertaDiaVenta] = useState([] as any)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [anchorElExitContingecia, setAnchorElExitoContingencia] = useState<HTMLButtonElement | null>(null)
    const [posContingencia, setPosContingencia] = useState([] as IPosContingencia[])
    const [facturasElectronicasSinGestionar, setFacturasElectronicasSinGestionar] = useState([] as any)
    const [ventasContingencia, setVentasContingencia] = useState([] as any)
    const { setSessionStorage, getSessionStorage, clearKeySessionStorage } = useSessionStorage()
    const [cliPropiosModal, setCliPropiosmodal] = useState(false)
    const [visibleModalFacturasElectronicas, setVisibleModalFacturasElectronicas] = useState(false)
    const [visibleModalVentasContingencia, setVisibleModalVentasContingencia] = useState(false)
    const { usuario } = Storage.getState()
    const [enviandoFactura, setEnviandoFactura] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [facturasContingencia, setFacturasContingencia] = useState<IDataFacturaContingencia>({
        mensaje: '',
        fechaProceso: new Date(),
        facturas: []
    })
    const [facturasReprocesadas, setFacturasReprocesadas] = useState<IFacturasReprocesadas[]>([])



    useEffect(() => {
        if (facturasReprocesadas.length != 0 && facturasReprocesadas.length === facturasContingencia.facturas.length) {

            const facturasErroneas = facturasContingencia.facturas.
                filter(factura => facturasReprocesadas.find(facturaReprocesada => facturaReprocesada.facturaId === factura.id)?.error === true);
            if (facturasErroneas.length > 0) {
                SwalTerpel({
                    title: `Hubo ${facturasErroneas.length} ${facturasErroneas.length > 1 ? 'facturas que no pudieron ser enviadas.' : 'factura que no se pudo enviar'} `,
                    icon: 'warning',
                    html: `<ul style="list-style:none">${facturasErroneas.map(factura => `<li>${factura.prefijo}${factura.consecutivo}<li>`).join('')}</ul>`,
                    onClose: () => obtenerFacturacContingencia()
                })
            } else {
                SwalTerpel({ title: 'Facturas de contingencia enviadas exitosamente', icon: 'success', onClose: () => obtenerFacturacContingencia() })
            }
        }
    }, [facturasReprocesadas])


    const progresoEnvio = () => {
        return facturasReprocesadas.length * 100 / facturasContingencia.facturas.length
    }

    const closeDrawer = () => {
        setOpenDrawer(false)
    }


    const badgeCount = () => {
        const visibleAlert = [posContingencia.length > 0,
        facturasElectronicasSinGestionar.length > 0,
        ventasContingencia.length > 0 && (usuario.tipo == 5 || usuario.tipo == 3)
            , ordenesCompra && ordenesCompra.length > 0,
        alert.length > 0
            , consecutivos.length > 0,
        alertaDiaVenta.length > 0,
        jornadasAbiertas && jornadasAbiertas.length > 0,]

        return visibleAlert.filter((alertas) => alertas === true).length

    }

    const modalAlertas = (title: string) => {
        setDataModal({
            [title]: false,
            isShow: true,
            alerta: title
        })
        const result = getSessionStorage('alertas')
        if (result !== 'N') {
            // console.log('asjhcd')
            const data = { ...result, [title]: { ...[title], vista: true } }
            clearKeySessionStorage('alertas')
            setSessionStorage('alertas', data)
        }
    }

    // getClientGlobal(usuario.empresas_id)
    const ObtenerPosContingencia = async () => {
        let data = await getPosContingencia({ empresas: [Number(identificadorEstacion)] })
        setPosContingencia(data.data)

    }

    const obtenerFacturacContingencia = async () => {
        let data = await getFacturasContigencia(identificadorEstacion)
        setFacturasContingencia(data.data as IDataFacturaContingencia)

    }
    useEffect(() => {
        getData()
        if (identificadorEstacion) {
            console.log('empresa', identificadorEstacion)
            loadFacturasElectronicasNoGestionadas()
            loadVentasContingencia()
            if (usuario.perfiles && usuario.perfiles.length > 0 &&
                PERFILESAUTORIZADOS.includes(usuario.perfiles[0].descripcion) &&
                !enviandoFactura) {
                obtenerFacturacContingencia()
            }
        }
    }, [updateData, identificadorEstacion])

    const loadFacturasElectronicasNoGestionadas = async () => {
        try {
            const respuestaFacturas = await getFE({
                tiendas: identificadorEstacion,
                estado: [
                    ESTADOS_FACTURACION_ELECTRONICA.ESPERA_RESPUESTA,
                    ESTADOS_FACTURACION_ELECTRONICA.ESPERA_AUTORIZACION,
                    ESTADOS_FACTURACION_ELECTRONICA.DEVUELTA_RECEPCION,
                    ESTADOS_FACTURACION_ELECTRONICA.NO_AUTORIZADA,
                    ESTADOS_FACTURACION_ELECTRONICA.DEVUELTO_EDOC,
                    ESTADOS_FACTURACION_ELECTRONICA.ERROR_ANTES_DE_ENVIAR,
                    ESTADOS_FACTURACION_ELECTRONICA.DOCUMENTO_NO_ECONTRADO
                ]
            })
            setFacturasElectronicasSinGestionar(respuestaFacturas.data)
        } catch (error) {
            console.error(error)
        }
    }

    const loadVentasContingencia = async () => {
        try {
            if(identificadorEstacion){
                const respuestaFacturas = await getVentasContingencia(
                    identificadorEstacion ?? 0,
                    moment()
                        .startOf('day')
                        .format('YYYY-MM-DD HH:mm:ss'),
                    moment()
                        .endOf('day')
                        .format('YYYY-MM-DD HH:mm:ss')
                )
                setVentasContingencia(respuestaFacturas.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getData = async () => {
        try {
            loadVentasContingencia()
            setSessionStorage('alertas', {
                secadoVirtual: '',
                jornadasAbiertas: '',
                consecutivos: '',
                ordCompra: '',
                alertaDiaVenta: ''
            })
            const [secadoVirtual, jornadasAbiertas, consecutivos, ordCompra, alertaDiaVenta] = await Promise.all([
                alertaSecadoVirtual(identificadorEstacion),
                obtenerJornadasAbiertas(identificadorEstacion),
                obtenerConsecutivo(identificadorEstacion),
                obtenerOrdenCompra(identificadorEstacion),
                getAlertaDiaVenta(identificadorEstacion)
            ])
            const result = sessionStorage.getItem('alertas')
            const consecutivosSeteados = JSON.parse(result as string)
            const valueConsecutivos = consecutivosSeteados.consecutivos
            if (valueConsecutivos === '') {

                const data = {
                    secadoVirtual: { data: secadoVirtual, vista: false },
                    jornadasAbiertas: { data: jornadasAbiertas, vista: false },
                    consecutivos: { data: consecutivos, vista: false },
                    ordCompra: { data: ordCompra, vista: false },
                    alertaDiaVenta: { data: alertaDiaVenta, vista: false }
                }
                setSessionStorage('alertas', data)
                setSecadoVirtual(secadoVirtual)
                setJornadasAbiertas(jornadasAbiertas)
                setConsecutivos(consecutivos)
                setOrdenesCompra(ordCompra)
                setAlertaDiaVenta(alertaDiaVenta)
                return

            }

            const {
                secadoVirtual: _secado,
                jornadasAbiertas: _jornada,
                consecutivos: _cons,
                ordCompra: _ord,
                alertaDiaVenta: _alert
            } = consecutivosSeteados
            if (_secado.data === secadoVirtual && _secado.vista === false) setSecadoVirtual(secadoVirtual)
            if (_jornada === jornadasAbiertas && _jornada.vista === false) setJornadasAbiertas(jornadasAbiertas)
            if (_cons.data === consecutivos && _cons.vista === false) setConsecutivos(consecutivos)
            if (_ord.data === ordCompra && _ord.vista === false) setOrdenesCompra(ordCompra)
            if (_alert.data === alertaDiaVenta && _alert.vista === false) setAlertaDiaVenta(alertaDiaVenta)
        } catch (error) {
            console.log(error)
        }
    }
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClickExitContingencia = (event: any) => {
        setAnchorElExitoContingencia(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseExitContingencia = () => {
        setAnchorElExitoContingencia(null);
    }
    const detallesConsecutiva = () => {
        return (
            <div className="mx-auto">
                <DataTableFilterDemo
                    paginator={true}
                    rows={10}
                    data={consecutivos}
                    columns={[
                        {
                            field: 'prefijo',
                            header: 'PREFIJO'
                        },
                        {
                            field: 'resolucion',
                            header: 'RESOLUCION'
                        },
                        {
                            field: 'fecha_inicio',
                            header: 'FECHA INICIAL'
                        },
                        {
                            field: 'fecha_fin',
                            header: 'FECHA FINAL'
                        },
                        {
                            field: 'consecutivo_inicial',
                            header: 'CONS. INICIAL',
                            number: true
                        },
                        {
                            field: 'consecutivo_actual',
                            header: 'CONS. ACTUAL',
                            number: true
                        },
                        {
                            field: 'consecutivo_final',
                            header: 'CONS. FINAL',
                            number: true
                        },
                        {
                            field: 'consecutivo_restante',
                            header: 'CONS. RESTANTES'
                        }
                    ]}
                />
            </div>
        )
    }

    const [alert, setAlert] = useState([])
    const seeAlerts = async () => {
        setAlert(await getGlobalAlertsV2(usuario.empresas_id))
    }

    useEffect(() => {
        seeAlerts()
        setInterval(seeAlerts, 600000)
    }, [])

    const detallesClientesPropios = () => {
        return (
            <div >
                <button className="btn btn-primary" onClick={() => setCliPropiosmodal(!cliPropiosModal)}>
                    Cerrar
                </button>
                <hr />
                <DataTableFilterDemo
                    paginator={true}
                    rows={10}
                    data={alert.map((datos: any) => ({
                        nombre: datos.nombre,
                        alerta_mensaje: datos.alerta_mensaje,
                        cupo: datos.cupo,
                        estado: datos.estado,
                        fecha: formatearFecha(datos.fecha, formatosFecha.fecha),
                        acciones: (
                            <Tooltip title="INACTIVAR" placement="top">
                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                        setGlobalAlertsV2({ id: +datos.id, estado: 'I' })
                                        setAlert(alert.filter((item: any) => +item.id != +datos.id))
                                        console.log('Eliminar')
                                    }}
                                >
                                    <Delete />
                                </button>
                            </Tooltip>
                        )
                    }))}
                    columns={COLUMNS_TABLE}
                />
            </div>
        )
    }
    const detallesJordanaActiva = () => {
        return (
            <div className="col-lg-12 col-12">
                <div className="jr-card">
                    <ProjectsList data={jornadasAbiertas} />
                </div>
            </div>
        )
    }
    const detallesCompraAbierta = () => {
        return (
            <div className="row mx-auto">
                <DataTableFilterDemo
                    paginator={true}
                    rows={10}
                    data={ordenesCompra}
                    columns={[
                        {
                            field: 'consecutivo',
                            header: 'CONSECUTIVO'
                        },
                        {
                            field: 'fecha',
                            header: 'FECHA'
                        },
                        {
                            field: 'responsable',
                            header: 'RESPONSABLE'
                        },
                        {
                            field: 'proovedor',
                            header: 'PROOVEDOR'
                        },
                        {
                            field: 'costo_total',
                            header: 'TOTAL'
                        }
                    ]}
                />
            </div>
        )
    }
    const detallesSecadoVirtual = () => {
        return (
            <div className="row mx-auto">
                <DataTableFilterDemo
                    paginator={true}
                    rows={10}
                    data={secadoVirtual}
                    columns={[
                        {
                            field: 'bodega',
                            header: 'BODEGA'
                        },
                        {
                            field: 'codigo',
                            header: 'CODIGO'
                        },
                        {
                            field: 'descripcion',
                            header: 'DESCRIPCION'
                        },
                        {
                            field: 'saldo',
                            header: 'SALDO'
                        },
                        {
                            field: 'volumen_minimo',
                            header: 'VOLUMEN MINIMO'
                        }
                    ]}
                />
            </div>
        )

    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const openExitContingencia = Boolean(anchorElExitContingecia)
    const idExitContingencia = openExitContingencia ? 'exit' : undefined
    const renderDetalles = () => {
        if (!dataModal.alerta) return <></>
        switch (dataModal.alerta.toLocaleUpperCase()) {
            case 'CONSECUTIVO':
                return detallesConsecutiva()
            case 'JORNADA_ABIERTA':
                return detallesJordanaActiva()
            case 'SECADO_VIRTUAL':
                return detallesSecadoVirtual()
            default:
                return detallesCompraAbierta()
        }
    }

    const enviarFacturasContingecia = async (id: number) => {
        try {
            await reprocesarFacturaElectronica(id);
            setFacturasReprocesadas(prev => [...prev, { facturaId: id, error: false }])
        } catch (error) {
            setFacturasReprocesadas(prev => [...prev, { facturaId: id, error: true }])
        }
    }


    const enviarFacturasAhora = async () => {
        try {
            setEnviandoFactura(true)
            setFacturasReprocesadas([])
            const peticiones = facturasContingencia.facturas.map(factura => enviarFacturasContingecia(factura.id))
            await Promise.allSettled(peticiones)
            setEnviandoFactura(false)
        } catch (error) {

        }
    }

    return (
        <>

            <div className="container_alertas" style={{ border: 'none' }}>
                <div className='container_alerta_contingencia' style={{ justifySelf: 'flex-start' }}>
                    {(facturasContingencia.facturas.length > 0) && <Paper style={{ width: '100%', }}  >

                        <Grid container spacing={2} style={{ padding: '10px' }}>
                            <Grid item xs={11} md={11} lg={11} xl={11} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className='hola' style={{ fontSize: '15px', fontWeight: 400 }}>Facturación electrónica de contigencia</Typography>
                                <Button startIcon={<EnviarIcon color={enviandoFactura ? 'none' : '#888A97'} />}
                                    className={!enviandoFactura && facturasContingencia.facturas.length != 0 ? 'boton_enviar_contingencia' : 'boton_enviar_contingencia_disabled'}
                                    disabled={enviandoFactura || facturasContingencia.facturas.length === 0}
                                    onClick={() => {
                                        SwalTerpel({ title: 'Se ha iniciado el envio de facturación de contingencia de manera exitosa', icon: 'success', onClose: () => console.log(facturasContingencia) })
                                        enviarFacturasAhora()
                                    }}>
                                    {enviandoFactura ? 'Enviando...' : 'Enviar Ahora'}
                                </Button>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'end', }}>
                                <button aria-describedby={idExitContingencia} style={{ border: 'none', backgroundColor: 'transparent' }} onClick={!openExitContingencia ? handleClickExitContingencia : handleCloseExitContingencia} >
                                    <FaInfoCircle style={{ color: '#2196f3' }} />
                                </button>
                                <Popper id={idExitContingencia} open={openExitContingencia} anchorEl={anchorElExitContingecia} >
                                    <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                                        <Alert onMouseLeave={handleCloseExitContingencia} style={{ border: '1px solid', borderColor: '#4065F6', textAlign: 'left', maxWidth: '300px' }} severity="info">Se han superado las fallas que originaron la contingencia, ahora puede realizar el envio de sus facturas electrónicas. <strong>{facturasContingencia.facturas.length} factura(s) por enviar</strong></Alert>
                                    </Box>
                                </Popper>


                            </Grid>
                            <Grid item xs={12}>
                                <LinearProgress value={progresoEnvio()} variant='determinate' />
                            </Grid>
                        </Grid>

                    </Paper>}


                </div>
                {/* ALERTA JORNADAS ABIERTAS */}

                <div  className="cont_filtro1" style={{ marginRight: '5px' }}>
                    <div className="cont_filtro" style={{ marginRight: '5px' }}>
                        <div className="cont_filtro_logo" onClick={() => setFilterOpen(!filterOpen)}>
                            <img src={Filter} alt="Filtro" />
                        </div>
                        {filterOpen && (
                            <div className="filter_container">
                                <FilterEntiti />
                            </div>
                        )}
                    </div>
                    <div className="cont_filtro" onClick={() => setOpenDrawer(!openDrawer)}>
                        <Badge overlap='rectangular' badgeContent={badgeCount()} color='primary'>
                            <div className="cont_filtro_logo" >
                                <BellOutlined style={{ fontSize: '40px' }} />
                            </div>
                        </Badge>
                    </div>
                </div>

            </div>
            <Modal
                centered
                show={dataModal?.isShow ?? false}
                onHide={() => setDataModal({ ...dataModal, isShow: false })}>
                <Modal.Header placeholder='' closeButton>
                    {dataModal.alerta &&
                        dataModal.alerta.toLocaleUpperCase() !== 'JORNADA_ABIERTA' &&
                        'ALERTAS DE'}{' '}
                    {dataModal.alerta && dataModal.alerta.replace('_', ' ').toLocaleUpperCase()}
                </Modal.Header>
                <Modal.Body>{renderDetalles()}</Modal.Body>
            </Modal>

            {cliPropiosModal && (
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        minHeight: '100%',
                        top: -10,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        zIndex: 9999,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    }}
                >
                    <div
                        style={{
                            padding: '20px',
                            width: '80%',
                            background: 'white',
                            minHeight: '100px',
                            border: '1px solid gray',
                            boxShadow: '0 0 20px gray',
                            // maxHeight: '600px',
                            borderRadius: '10px',
                            overflowY: 'auto',
                            position: 'fixed',
                            top: '10%'
                        }}
                    >
                        {detallesClientesPropios()}
                    </div>
                </div>
            )}

            <ModalAlertaFacturaElectronica
                facturas={facturasElectronicasSinGestionar}
                show={visibleModalFacturasElectronicas}
                onHide={() => setVisibleModalFacturasElectronicas(false)}
            />

            <ModalAlertaVentasContingencia
                facturas={ventasContingencia}
                show={visibleModalVentasContingencia}
                onHide={() => setVisibleModalVentasContingencia(false)}
            />

            <Drawer open={openDrawer} onClose={closeDrawer} maskStyle={{ backgroundColor: 'transparent' }} closeIcon={<CloseOutlined style={{ color: 'red', }} />} getContainer={false} title={<Typography >Notificaciones</Typography>} rootStyle={{ outline: 'none' }}>
                <div className='drawerAlert text-center'>

                    {badgeCount() == 0 && <Typography >No hay Notificaciones que mostrar</Typography>}
                    <div className="cont_alert" style={{ justifyContent: 'flex-start' }}>
                        {jornadasAbiertas && jornadasAbiertas.length > 0 && (
                            <Alert
                                className={'hoverAlertas alerta'}
                                style={{ backgroundColor: '#D1FFE4', borderRadius: '10px' }}
                                severity="success"
                                onClick={() => modalAlertas('jornada_abierta')}
                            >

                                <AlertTitle style={{ margin: '0px' }}>JORNADAS ABIERTAS</AlertTitle>
                            </Alert>
                        )}
                    </div>

                    {/* ALERTA DIA VENTAS */}
                    <div className="cont_alert" style={{ justifyContent: 'flex-start' }}>
                        {alertaDiaVenta.length > 0 && (
                            <Alert
                                className={'hoverAlertas alerta'}
                                style={{
                                    minWidth: '90%',
                                    backgroundColor: '#D1FFE4',
                                    borderRadius: '10px',
                                    boxShadow: '4px 4px 8px rgba(0,0,0,0.3)'
                                }}
                                severity="success"
                                onClick={() => modalAlertas('Alerta _dia_venta')}
                            >
                                <AlertTitle style={{ margin: '0px' }}>ALERTA DIA VENTAS</AlertTitle>
                            </Alert>
                        )}
                    </div>

                    {/* ALERTA CONSECUTIVO */}
                    <div className="cont_alert">
                        {consecutivos.length > 0 && (
                            <Alert
                                className={'hoverAlertas alerta'}
                                style={{ backgroundColor: '#F8F5FE', borderRadius: '10px' }}
                                severity="info"
                                onClick={() => modalAlertas('consecutivo')}
                            >
                                <AlertTitle style={{ margin: '0px', color: '#876FC1' }}>CONSECUTIVO</AlertTitle>
                            </Alert>
                        )}
                    </div>

                    {/* ALERTA CLIENTES PROPIOS */}
                    <div className="cont_alert">
                        {alert.length > 0 && (
                            <Alert
                                className={'hoverAlertas alerta'}
                                style={{ backgroundColor: '#F8F5FE', borderRadius: '10px' }}
                                severity="error"
                                onClick={() => setCliPropiosmodal(!cliPropiosModal)}
                            >
                                <AlertTitle style={{ margin: '0px', color: '#876FC1' }}>CLIENTES PROPIOS</AlertTitle>
                            </Alert>
                        )}
                    </div>

                    {/* ALERTA DE ORDENES DE COMPRA */}
                    <div className="cont_alert">
                        {ordenesCompra && ordenesCompra.length > 0 && (
                            <Alert
                                className={'hoverAlertas alerta'}
                                style={{ backgroundColor: '#F1FAFD', borderRadius: '10px' }}
                                severity="info"
                                onClick={() => modalAlertas('ordenes_entrada')}
                            >
                                <AlertTitle style={{ margin: '0px' }}>ORD. COMPRAS SIN ENTRADA</AlertTitle>
                            </Alert>
                        )}
                    </div>

                    {/* ALERTA DE VENTAS DE CONTINGENCIA */}
                    <div className="cont_alert">
                        {ventasContingencia.length > 0 && (usuario.tipo == 5 || usuario.tipo == 3) && (
                            <Alert
                                className={'hoverAlertas alerta'}
                                style={{ backgroundColor: '#F1FAFD', borderRadius: '10px' }}
                                severity="info"
                                onClick={() => setVisibleModalVentasContingencia(true)}
                            >
                                <AlertTitle style={{ margin: '0px' }}>VENTAS MANUALES INGRESADAS</AlertTitle>
                            </Alert>
                        )}
                    </div>

                    {/* ALERTA DE FACTURAS ELECTRONICA SIN GESTIONAR */}
                    <div className="cont_alert">
                        {facturasElectronicasSinGestionar.length > 0 && (
                            <Alert
                                className={'hoverAlertas alerta'}
                                style={{ backgroundColor: '#F1FAFD', borderRadius: '10px' }}
                                severity="info"
                                onClick={() => setVisibleModalFacturasElectronicas(true)}
                            >
                                <AlertTitle style={{ margin: '0px' }}>F. ELECTRONICAS SIN GESTIONAR</AlertTitle>
                            </Alert>
                        )}
                    </div>
                    <div className="cont_alert">
                        {(posContingencia.length > 0) && (
                            <>

                                <Alert aria-describedby={id} severity='warning' className='hoverAlertas alerta' onClick={handleClick}>
                                    <Badge badgeContent={posContingencia.length} color="error" >
                                        <AlertTitle style={{ margin: '0px' }}>EQUIPO SIN CONEXION CON HO</AlertTitle>
                                    </Badge>
                                </Alert>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    style={{ maxHeight: '700px', overflowY: 'scroll' }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >

                                    {posContingencia.map((alertContingencia) => (
                                        <Alert style={{ margin: '5px' }} severity="warning" onClick={() => { SwalTerpel({ title: 'Estamos presentando fallas que podrían generar facturación de contingencia, por favor verifique:', icon: 'error', html: '<ul style="text-align: left"><li>Su conexión a internet</li> <li>Daño de POS</li><ul>' }); handleClose() }}>
                                            <AlertTitle>{alertContingencia.alias}</AlertTitle>
                                            El equipo de la <strong>Isla {alertContingencia.isla}</strong> se encuentra en estado de contingencia
                                        </Alert>
                                    ))}
                                </Popover>
                            </>



                        )}
                    </div>
                </div>
            </Drawer>
        </>
    )
}
export default PanelAlertas
