import ContainerHeader from 'components/ContainerHeader'
import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import './index.css'
import { DIFF_READING_DEFAULT, INITIAL_STATE, parseDifferenceReading, parseResponseConsolidado } from './Conciliacion.utils'
import FormularioConciliadorPDV from './components/FormularioConciliadorPDV'
import NovedadesConciliacion from './components/NovedadesConciliacion'
import AcordionDetail from './components/AcordionDetail'
import { getDiaConsolidado, getNovedades, obtenerDiferenciasLecturaService } from './services/conciliacionPDV.services'
import { formatearFecha } from 'util/formatDate'
import { IStateConsolidado } from './utils/interfaces'
import { objetoVacio } from 'util/utils'
import Mjs from 'components/Mjs/Mjs'
import { Spin, notification, Button, Popconfirm } from 'antd'
import { sessionCaducada, sonMismaFecha } from 'util/funciones'
import ModuleAllowed from 'components/ModuleAllowed/ModuleAllowed'
import DiferencesReading from './components/DiferencesReading'


const ConciliacionPDV = ({ match }: any) => {
  const [form, setForm] = useState(INITIAL_STATE)
  const [consolidadoData, setConsolidadoData] = useState<IStateConsolidado>({} as IStateConsolidado)
  const [novedadesList, setNovedadesList] = useState([])
  const [loaders, setloaders] = useState({
    loadingNovedades: false,
    loadingConsolidado: false,
    loadingDiferenciasLectura:false
  })

  const [error, setError] = useState({
    errorNovedades: true,
    errorConsolidado: true
  })


  const handlerForm = (name: string, value: any) => [
    setForm((prev) => ({ ...prev, [name]: value }))
  ]

  const handlerError = (name: string, value: boolean) => {
    setError(prev => ({ ...prev, [name]: value }))
  }

  const handlerLoaders = (name: string, value: boolean) => {
    setloaders(prev => ({ ...prev, [name]: value }))
  }

  const getData = async () => {
    if (form.estacion) {
      handlerLoaders('loadingConsolidado', true)
      handlerLoaders('loadingNovedades', true)
      const queryParams = { empresaId: form.estacion, startDate: formatearFecha(form.fecha, 'YYYY-MM-DD') }
      try {
        const response = await getNovedades(queryParams)
        setNovedadesList(response.data.result)
        handlerError('errorNovedades', false)
      } catch (error: any) {
        console.log(error.response);
        notification.error({ message: 'Ocurrió un al obtener las novedades del día' })
        handlerError('errorNovedades', true)
      } finally {
        handlerLoaders('loadingNovedades', false)
      }
      try {
        const responseConsolidado = await getDiaConsolidado(queryParams);
        const {data} = await obtenerDiferenciasLecturaService(queryParams)
        console.log('CLG: ', data);
        setConsolidadoData({
          combustible: parseResponseConsolidado(responseConsolidado.data.result.fuel),
          kiosco: parseResponseConsolidado(responseConsolidado.data.result.kiosco),
          canastilla: parseResponseConsolidado(responseConsolidado.data.result.canastilla),
          diferenciaLectura:{
            detalle:parseDifferenceReading(data?.result?.detallado || {}),
            totales: objetoVacio((data?.result?.total || {})) ? DIFF_READING_DEFAULT : data.result.total
          }
        })
        handlerError('errorConsolidado', false)
      } catch (error: any) {
        console.log(error)
        if (error?.response?.status == 401) {
          sessionCaducada()
        } else {
          notification.error({ message: 'Ocurrió un error al obtener los datos' })
        }
        handlerError('errorConsolidado', true)
      } finally {
        handlerLoaders('loadingConsolidado', false)
      }
    } else {
      notification.info({ message: 'Por favor, seleccione una estación' })
    }
  }

  const deshabilitarConciliacion = () => novedadesList.length != 0 || sonMismaFecha(form.fecha, new Date()) || error.errorConsolidado || error.errorNovedades


  useEffect(() => {
    setNovedadesList([])
    setConsolidadoData({} as IStateConsolidado)
  }, [form.estacion, form.fecha])





  return (
    <div style={{ padding: '20px' }}>

      <ContainerHeader title={'CONCILIADOR EDS'} match={match} />
      <ModuleAllowed nameModule='CONCILIACIÓN PDV'  >
        <FormularioConciliadorPDV loading={loaders.loadingConsolidado} onSumbit={() => getData()} form={form} handlerForm={handlerForm} setForm={setForm} key={'form'} />
        {objetoVacio(consolidadoData) ? <Spin spinning={loaders.loadingConsolidado}> <Mjs mensaje={'Consulte la información del día'} /></Spin>

          : <>
            <Grid container style={{ marginTop: '10px' }} spacing={2}>
              <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} item xs={12} className='fix-grid' md={6}>
                <NovedadesConciliacion loading={loaders.loadingNovedades} novedadesList={novedadesList} />
              </Grid>
              <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} item xs={12} md={6}>
                <div className={'fix-grid-botones'} >
                  <Button className='btn btn-danger' style={{ background: '#DE1B08', color: '#FFFFFF', width: '12rem' }} >Cambio medio de pago</Button>
                  <Popconfirm

                    disabled={novedadesList.length != 0 || sonMismaFecha(form.fecha, new Date()) || error.errorConsolidado || error.errorNovedades}
                    title="¿Está seguro de conciliar el día?"
                    description="NOTA: Una vez seleccione aceptar esta acción no podrá revertir"
                    onConfirm={() => notification.success({ message: 'Conciliando día' })}
                    onOpenChange={() => console.log('open change')}

                  >
                    <Button style={deshabilitarConciliacion() ? { background: 'lightgray', color: '#FFFFFF', width: '12rem' } : { background: '#0FAB0B', color: '#FFFFFF', width: '12rem' }} disabled={deshabilitarConciliacion()} >Conciliar día</Button>
                  </Popconfirm>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '10px' }}>
              <Grid item xs={12} style={{ marginTop: '10px' }}>
                <AcordionDetail datos={consolidadoData.combustible} />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '10px' }}>
                <AcordionDetail datos={consolidadoData.kiosco} negocio='KIOSCO' />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '10px' }}>
                <AcordionDetail datos={consolidadoData.canastilla} negocio='CANASTILLA' />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '10px' }}>
               <DiferencesReading data={consolidadoData.diferenciaLectura} />
              </Grid>
            </Grid>
          </>}
      </ModuleAllowed>






    </div >
  )
}

export default ConciliacionPDV