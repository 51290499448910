import React from 'react'
import {
    AppstoreOutlined,
    CarOutlined,
    FileProtectOutlined,
    MailOutlined,
    SettingOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    UsergroupAddOutlined,
    DashboardOutlined,
    UserOutlined,
    FundProjectionScreenOutlined,
    ReconciliationOutlined,
    ShoppingOutlined,
    RocketOutlined,
    ClearOutlined,
    PartitionOutlined
} from '@ant-design/icons'
export const styleIcons = {
    color: '#DF1A0A',
    fontWeight: 'bold',
    fontSize: '20px'
}

export const styleIconsChildre = {
    color: '#DF1A0A',
    fontWeight: 'bold',
    fontSize: '10px'
}

//LIBRERIA ICONOS: https://ant.design/components/icon
// React.Component.prototype.$icon = {
//     AppstoreOutlined: <AppstoreOutlined style={styleIcons} />,
//     CarOutlined: <CarOutlined style={styleIcons} />,
//     FileProtectOutlined: <FileProtectOutlined style={styleIcons} />,
//     MailOutlined: <MailOutlined style={styleIcons} />,
//     SettingOutlined: <SettingOutlined style={styleIcons} />,
//     ShopOutlined: <ShopOutlined style={styleIcons} />,
//     ShoppingCartOutlined: <ShoppingCartOutlined style={styleIcons} />,
//     UsergroupAddOutlined: <UsergroupAddOutlined style={styleIcons} />,
//     UserOutlined: <UserOutlined style={styleIcons} />,
//     QuestionOutlined: <QuestionOutlined style={styleIcons} />,
// }

export const RenderItemMenu = (label, key, icon, children, type) => {
    return {
        key,
        icon,
        children,
        label,
        type
    }
}

const AvailableIcons = [
    {
        name: 'AppstoreOutlined',
        icon: <AppstoreOutlined style={styleIcons} />
    },
    {
        name: 'CarOutlined',
        icon: <CarOutlined style={styleIcons} />
    },
    {
        name: 'FileProtectOutlined',
        icon: <FileProtectOutlined style={styleIcons} />
    },
    {
        name: 'MailOutlined',
        icon: <MailOutlined style={styleIcons} />
    },
    {
        name: 'SettingOutlined',
        icon: <SettingOutlined style={styleIcons} />
    },
    {
        name: 'ShopOutlined',
        icon: <ShopOutlined style={styleIcons} />
    },
    {
        name: 'ShoppingCartOutlined',
        icon: <ShoppingCartOutlined style={styleIcons} />
    },
    {
        name: 'UsergroupAddOutlined',
        icon: <UsergroupAddOutlined style={styleIcons} />
    },
    {
        name: 'UserOutlined',
        icon: <UserOutlined style={styleIcons} />
    },
    {
        name: 'FundProjectionScreenOutlined',
        icon: <FundProjectionScreenOutlined style={styleIcons} />
    },
    {
        name: 'DashboardOutlined',
        icon: <PartitionOutlined style={styleIcons} />
    },
    {
        name: 'ReconciliationOutlined',
        icon: <ReconciliationOutlined style={styleIcons} />
    },
    {
        name: 'ShoppingOutlined',
        icon: <ShoppingOutlined style={styleIcons} />
    },
    {
        name: 'RocketOutlined ',
        icon: <RocketOutlined style={styleIcons} />
    },
    {
        name: 'ClearOutlined',
        icon: <ClearOutlined style={styleIcons} />
    },
    {
        name: 'PartitionOutlined',
        icon: <PartitionOutlined style={styleIcons} />
    }
]

export const RenderIcon = IconName => {
    const IconRender = AvailableIcons.find(icon => icon.name.trim() === IconName)
    // console.log(IconRender);
    return IconRender?.icon
}
