import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Swal from 'sweetalert2'
import Storage from 'util/store/Storage'
import Servidor from 'util/Servidor'
import swal from 'sweetalert'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import moment from 'moment'
import '../aplication.css'
import { login , forgetPassword } from './helper'


const isMasterlub = (negocio) => (negocio == '71'|| negocio == '19') ? 'red' : '#FFFFFF'

const renderStyle720 = () => {
  return window.screen.width > 720 ? { padding: 0, width: '100%', height: '100%' } : { display: 'none' }
}

const checkUser = () => {

  const { dominio, tipo_empresa, negocio } = Storage.getState()

  let empresa = ''

  if (tipo_empresa === 3) {
    empresa = negocio
  } else {
    empresa = dominio
  }
  return empresa
}

const SignIn = () => {
    const { loaderDominio } = Storage.getState()
    const initialState = {
        txtUser: '',
        txtPass: '',
        loader: false,
        loader2: true,
        dialogOlvideContraseña: false,
        usernameForget: '',
        cedula: '',
        color1: '#3f51b5',
        color2: '#3f51b5'
    }
    const [stateProperties, setStateProperties] = useState(initialState)

    const handleDialogOlvideContrasena = () => {
        setStateProperties({
            ...stateProperties,
            dialogOlvideContraseña: !stateProperties.dialogOlvideContraseña,
            cedula: '',
            usernameForget: ''
        })
    }

    const handleLoader = () => {
        setStateProperties({
            ...stateProperties,
            loader: !stateProperties.loader
        })
    }

    const handleChangeText = name => event => {
        setStateProperties({
            ...stateProperties,
            [name]: event.target.value
        })
    }

    const validar = () => {
        if (stateProperties.txtUser === '') {
            Swal.fire({
                icon: 'error',
                title: 'ERROR',
                text: '!DEBE LLENAR EL CAMPO DE USUARIO!'
            })
            return false
        } else if (stateProperties.txtPass === '') {
            Swal.fire({
                icon: 'error',
                title: 'ERROR',
                text: '!DEBE LLENAR EL CAMPO DE CLAVE!'
            })
            return false
        }

        return true
    }

    const actualizarEstado = (token, userall) => {
        Storage.dispatch({
            type: 'INICIO_DE_SESION',
            token,
            usuario: userall,
            obtencionInfoUsuario: true
        })
        window.location.reload()
    }

    const iniciarSesion = async() => {
        let validado = validar()
        if (validado) {
            handleLoader()

            let user = stateProperties.txtUser
            let pass = stateProperties.txtPass

            let datos = {
                user: user.toUpperCase(),
                pass: pass,
                identificadorNegocio: checkUser()
            }
            await login(datos, actualizarEstado, handleLoader)
        }
    }

    const keyBuscar = e => {
        if (e.key === 'Enter') {
            iniciarSesion()
        }
    }

    const enviarDatos = async(evt )=> {
        const { cedula, usernameForget } = stateProperties
        evt.preventDefault()
        if (cedula.length === 0) {
            swal('ERROR', 'LA IDENTIFICACION ES NECESARIA', 'warning')
        } else if (usernameForget.trim() == '' || usernameForget.length === 0) {
            swal('ERROR', 'EL CAMPO USUARIO NO PUEDE ESTAR VACIO', 'warning')
        } else {
            const usuario = usernameForget
            const num_documento = cedula
            const data = {
                identificacion: num_documento,
                usuario: usuario,
                dominio: Storage.getState().dominio
            }
            const headers = {
                Authorization: Servidor.obtenerToken(),
                versionapp: Servidor.versionApp,
                versioncode: Servidor.versionCode,
                fecha: moment().format(),
                aplicacion: Servidor.aplicacion,
                identificadordispositivo: Servidor.aplicacion
            }
            await forgetPassword(data, headers, handleDialogOlvideContrasena)
        }
    }




    const renderStyleColor = color2 => {
        return { backgroundColor: color2, color: '#ffffff' }
    }

    const renderHtml = () => {
        let { txtUser, txtPass, cedula, usernameForget, dialogOlvideContraseña } = stateProperties
        let { dominio, negocio, color1 } = Storage.getState()
        return dominio === 0 ? (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div
                    className="col-md-12 hidden-sm hidden-xs-down hidden-sm-down"
                    style={{ padding: 0, width: '100%', height: '100%' }}
                >
                    <img
                        style={{ width: '100%', height: '100%' }}
                        draggable="false"
                        src={Servidor.hostMultimedia + '/pagenotfound.svg'}
                        alt="login"
                    ></img>
                </div>
            </div>
        ) : (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="col-md-7 col-lg-7 hidden-sm hidden-xs-down hidden-sm-down" style={renderStyle720()}>
                    <img
                        style={{ width: '100%', height: '100%' }}
                        src={Servidor.hostMultimedia + dominio + '/pagina/loginizq.jpg'}
                        alt="login"
                        draggable="false"
                    ></img>
                </div>
                <div
                    className="col-md-5 col-lg-5 mx-auto"
                    style={{
                        height: '100%',
                        backgroundColor: '#FFFFFF',
                        backgroundSize: 'cover',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <div className="app-login-main-content mx-auto " style={{ boxShadow: 'none' }}>
                        <div
                            className="app-logo-content d-flex derecha mx-auto"
                            style={{ backgroundColor: isMasterlub(negocio)}}
                        >
                            <img
                                src={Servidor.hostMultimedia + negocio + '/pagina/logo_login_177_65.png'}
                                alt="Negocio"
                                title="Negocio"
                                draggable="false"
                            />
                        </div>

                        <div className="app-login-content ">
                            <div className="app-login-form">
                                <form>
                                    <fieldset>
                                        <TextField
                                            label="Usuario"
                                            id="cy-user"
                                            fullWidth
                                            onChange={handleChangeText('txtUser')}
                                            defaultValue={txtUser}
                                            margin="normal"
                                            className="mt-1 my-sm-3"
                                        />
                                        <TextField
                                            type="password"
                                            id="cy-pass"
                                            label="Contraseña"
                                            fullWidth
                                            onChange={handleChangeText('txtPass')}
                                            defaultValue={txtPass}
                                            margin="normal"
                                            className="mt-1 my-sm-3"
                                            onKeyDown={keyBuscar}
                                        />

                                        <div className="mb-3 d-flex align-items-center justify-content-between">
                                            <Button
                                                id="cy-btn-signin"
                                                onClick={() => iniciarSesion()}
                                                variant="contained"
                                                style={renderStyleColor(color1)}
                                            >
                                                Entrar
                                            </Button>

                                            <Link
                                                to="#"
                                                onClick={handleDialogOlvideContrasena}
                                                style={{ color: color1 }}
                                            >
                                                Olvidé mi contraseña
                                            </Link>

                                            <Dialog
                                                open={dialogOlvideContraseña}
                                                onClose={handleDialogOlvideContrasena}
                                                aria-labelledby="form-dialog-title"
                                                maxWidth={'md'}
                                                fullWidth={true}
                                                style={{ padding: '0', zIndex: 500 }}
                                            // disableBackdropClick={false}
                                            >
                                                <DialogContent style={{ padding: '30px', zIndex: 500 }}>
                                                    <div className="row">
                                                        <h3>OLVIDE MI CONTRASEÑA</h3>
                                                    </div>
                                                    <div className="row">
                                                        <div className={'col-md-12'}>
                                                            <label htmlFor="identificacion" className="col-form-label">
                                                                IDENTIFICACIÓN
                                                            </label>
                                                            <input
                                                                id="numordencompra"
                                                                placeholder="Escriba su numero de idenificacion"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                onChange={handleChangeText('cedula')}
                                                                type="text"
                                                                value={cedula}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className={'col-md-12'}>
                                                            <label htmlFor="identificacion" className="col-form-label">
                                                                USUARIO
                                                            </label>
                                                            <input
                                                                id="usuario"
                                                                placeholder="Escriba su usuario"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                onChange={handleChangeText('usernameForget')}
                                                                type="text"
                                                                value={usernameForget}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: '10px' }}>
                                                        <button
                                                            className="btn btn-success col-md-3"
                                                            onClick={enviarDatos}
                                                        >
                                                            ENVIAR
                                                        </button>
                                                        <button
                                                            className="btn btn-danger col-md-3"
                                                            onClick={handleDialogOlvideContrasena}
                                                        >
                                                            CANCELAR
                                                        </button>
                                                    </div>
                                                </DialogContent>
                                            </Dialog>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                            <div className="footerLogin">
                                <div>
                                    <span style={{ color: color1, marginLeft: '10px' }}>
                                        {'v.' + Servidor.versionApp}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    return loaderDominio ? (
        <div style={{ width: '100%' }}>
            <center
                style={{
                    display: 'flex',
                    height: '100vh'
                }}
            ></center>
        </div>
    ) : (
        renderHtml()
    )
}

export default SignIn
