import Indicadores, {IndicadorBody, IndicadorHeader, ContainerItems, IndicadorFooterItem, IndicadorFooter} from './Indicadores'

const Indicador = Object.assign(Indicadores,{
    Header: IndicadorHeader,
    Body: IndicadorBody,
    Footer: IndicadorFooter,
    Item: IndicadorFooterItem,
    container: ContainerItems
})

export default Indicador