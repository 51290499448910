import { Button, Col, Drawer, List, Row, Spin, notification } from 'antd'
import ContainerHeader from 'components/ContainerHeader'
import React, { useEffect, useState } from 'react'
import './regionales.css'
import RegionalCard from './components/RegionalCard/RegionalCard'
import { getRegionalService } from 'services/selectServices/services'
import Storage from "util/store/Storage";
import FormularioRegionales from './components/FormularioRegionales/FormularioRegionales'

const Regionales = ({ match }: any) => {
    const { negocio } = Storage.getState()
    const [booleanStates, setBooleanStates] = useState({
        loadingRegionals: false,
        openForm: false,
    })

    const [id_regional_selected, setId_regional_selected] = useState<number | null>(null)
    const [Regionales, setRegionales] = useState<any[]>([])

    const handlerBooleanStates = (name: string, value: boolean) => (setBooleanStates((prev) => ({ ...prev, [name]: value })))

    const closeDrawer = () => {
        setId_regional_selected(null)
        handlerBooleanStates('openForm', false)
    }
    const getRegionals = async () => {
        try {
            handlerBooleanStates('loadingRegionals', true)
            const regionals = await getRegionalService(negocio)
            setRegionales(regionals)
        } catch (error) {
            console.log(error)
            notification.error({
                message: 'Ocurrió un error al obtener las regionales'
            })
        } finally {
            handlerBooleanStates('loadingRegionals', false)

        }
    }
    useEffect(() => {
        getRegionals()
    }, [])

    return (
        <>
            <Row itemType='flex' align='top' justify='center'>
                <Col xxl={16} xl={20} lg={20} md={22} sm={24} xs={24} >
                    <Row justify="center">
                        <Col xxl={24} xl={24} lg={24} sm={24} md={24} xs={24}>
                            <ContainerHeader title={'Regionales'} match={match} />
                        </Col>
                    </Row>
                    <Spin spinning={booleanStates.loadingRegionals}>
                        <Row justify="center">
                            <Col xxl={24} xl={24} lg={24} sm={24} md={24} xs={24}>
                                <Row align={'top'} justify={'start'}>
                                    <Col className='cards-container' xxl={24} xl={24} lg={24} sm={24} md={24} xs={24}>
                                        <Button type='default' size='large' onClick={()=>{
                                            handlerBooleanStates('openForm',true)
                                        }}>
                                            Crear regional
                                        </Button>
                                        <List
                                            dataSource={Regionales}
                                            renderItem={(item) => (<RegionalCard onEdit={() => {
                                                setId_regional_selected(item.id_regional)
                                                handlerBooleanStates('openForm', true)
                                            }} regional={item} />)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Spin>

                </Col>

            </Row>
            <Drawer
                title={`${id_regional_selected === null ? 'Crear' : 'Editar'} Regional`}
                open={booleanStates.openForm}
                onClose={closeDrawer}
                destroyOnClose={true}
            >
                <FormularioRegionales
                    id={id_regional_selected}
                    refreshData={getRegionals}
                    onClose={closeDrawer} />
            </Drawer>
        </>
    )
}

export default Regionales