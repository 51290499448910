import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {toggleCollapsedNav} from 'actions/Setting';
import { useDispatch, useSelector } from 'react-redux';
import './index.css'




const getDisplayString = (sub) => {
  const arr = sub.split("-");
  if (arr.length > 1) {
    return arr[0].charAt(0).toUpperCase() + arr[0].slice(1) + " " + arr[1].charAt(0).toUpperCase() + arr[1].slice(1)
  } else {
    return sub.charAt(0).toUpperCase() + sub.slice(1)
  }

  

};
const getUrlString = (path, sub, index) => {
  if (index === 0) {
    return '/';
  } 
};

const ContainerHeader = ({ title, match, transparent = false }) => {
  let path = "";
  let subPath = [];
  if (typeof match  ===  'object') {
    path = match.path.substr(1);
    subPath =  path.split('/');
  }

  const dispatch = useDispatch();
  const {navCollapsed} = useSelector(({settings}) => settings);


 subPath[0] = 'Home'
 if(subPath.length > 2){
  subPath[2] = subPath[2].replace('kiosco','')
 }


  return (
    <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center header-container" style={{  backgroundColor:transparent ? 'transparent' : undefined }}>
      <h2  style={{fontSize: '14px', fontWeight:'bold'}}>{title}</h2>

      <Breadcrumb className="mb-0" tag="nav">
        {subPath.map((sub, index) => {
          return <BreadcrumbItem 
          style={{cursor:'pointer'}}
          onClick={()=> dispatch(toggleCollapsedNav(!navCollapsed))}
          active={subPath.length === index + 1}
            tag={subPath.length === index + 1 ? "span" : "a"} key={index}
            href={getUrlString(path, sub, index)}>{getDisplayString(sub)}</BreadcrumbItem>
        }
        )}
      </Breadcrumb>
    </div>
  )
};

export default ContainerHeader;

