import React, { useMemo, useState } from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import configureStore, { history } from './store'
import App from './containers/App'
import { ModalManagerContextProvider, LastModulesVisitedContex } from 'Providers'
import { ModalManager } from 'common/ModalManager'
import { EmpresasSelectProvider } from 'app/vistas/Dashboard/context/DashBoardContext'
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';
import { MainContext } from 'context/mainContext/MainContext';
import { getService, service } from 'api';
import './App.css';


export const store = configureStore()

const MainApp = () =>{
  const [state, setState] = useState<any>({});

  const value = useMemo(() => {
    return {
      state,
      setState,
      getService,
      service
    }
  }, [state])



  return(
  <MainContext.Provider value ={value} >
  <EmpresasSelectProvider>

        <LastModulesVisitedContex>
            <Provider store={store}>
              <ConfigProvider locale={esES}  theme={{
                token: {
                  colorPrimary: '#BD412E',
                  fontFamily:'terpelPosFont'
                },
                }} getPopupContainer={(triggerNode: HTMLElement | undefined) => (triggerNode?.parentNode as HTMLElement) || document.body }>
                <ModalManagerContextProvider>
                    <ConnectedRouter history={history}>
                        <ModalManager />
                        <Switch>
                            <Route path="/" component={App} />
                        </Switch>
                    </ConnectedRouter>
                </ModalManagerContextProvider>
              </ConfigProvider>
            </Provider>
        </LastModulesVisitedContex>
    </EmpresasSelectProvider>
    </MainContext.Provider>
)
}
export default MainApp
