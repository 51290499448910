import React, { useEffect, useState } from 'react'
import jsPDF from 'jspdf'
import xlsx from 'xlsx'
import { applyPlugin } from 'jspdf-autotable'
import 'primereact/resources/primereact.min.css'
import 'primereact/components/datatable/DataTable.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.css'
import './prime.css'
import { DataTable as PRDatatable, DataTableProps } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import excelIcon from '../assets/images/excel.png'
import PdfIcon from '../assets/images/pdf.png'
import Storage from 'util/store/Storage'
import { esDominioFuelly, esNumero, snakeCaseToSeparatedUppercase, snakeCaseToSeparatedUppercase2 } from 'util/utils'
applyPlugin(jsPDF)

export interface ColumnsDatatable {
  field: string
  header: string
  number?: boolean | number
  frozen?: boolean
  style?: React.CSSProperties
}
export interface ColumnsPDF {
  header: string
  dataKey: string
}
export interface PropsDatatable extends DataTableProps {
  data: any[]
  columns: ColumnsDatatable[]
  columnsPDF?: ColumnsDatatable[]
  noExport?: boolean
  fontSize?: number
  nombreExcel?: string
  valueSearch?:string
  paperSheetSizePdf?:string | number[]
  headerRigth?:boolean
  nombrePDf?: string
  noRefresh?: boolean
  select?: any
  haveFormat?: any[]
  noPuntos?:boolean
  noPdf?: boolean
  hasTag?:boolean

  noExcel?:boolean
  extra?:React.ReactNode,
  extra2?:React.ReactNode,
  style?:React.CSSProperties
  worksheetName?:string
  idCy?: string
  onRefresh?: any;
  ignoreFormat?:string[]
}
// @ts-ignore
const DataTableFilterDemo: React.FC<PropsDatatable> = props => {
  const {
    valueSearch='',
    data,
    columns,
   
    extra,
    extra2,
    hasTag = false,
    headerRigth = false,
    columnsPDF = columns,
    noExport = false,
    rows = 10,
    fontSize = 9,
    nombreExcel = 'Excel',
    nombrePDf = 'PDF',
    className,
    noRefresh = false,
    haveFormat = [],
    noPuntos=false,
    noPdf = false,
    noExcel = false,
    worksheetName = 'data',
    idCy = 'demo',
    ignoreFormat = [],
    paperSheetSizePdf = 'legal'
  } = props
  const [globalFilter, setGlobalFilter] = useState('')
  const { actualizarvista } = Storage.getState()

  // funcion para catualizar la vista donde se envuentra el componente
  const actualizar = async() => {
    // Validar si viene la prop onRefresh, si no entonces poner por defecto actualizarvista
    if ( props.onRefresh ) {
      await props.onRefresh();
    } else {
      Storage.dispatch({
        type: 'ACTUALIZACION_DATOS',
        actualizarvista: !actualizarvista
      });
    }

  }

  const textoCentrado = (doc: any, text: string, alto: number): any => {
    const textWidth = (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor
    const textOffset = (doc.internal.pageSize.width - textWidth) / 2
    return doc.text(textOffset, alto, text)
  }

  useEffect(() => {
    setGlobalFilter(valueSearch)
  }, [valueSearch])



  // funcion para exportar pdf
  const exportPdf = (): void => {
    const headersNoPrint = ['ACCIONES', 'ACCION', 'VER', 'DETALLES', 'DETALLE', 'OPCIONES', 'OPCION','BORRAR']
    const doc: any = new jsPDF('l', 'pt', paperSheetSizePdf)
    const data2 = hasTag ? data.map(key =>(key.estado.props.children ?{...key,estado:key.estado.props.children}:key)) : data
    doc.setFontSize(14)
    textoCentrado(doc, nombrePDf, 20)
    doc.setFont('helvetica', 'italic')
    doc.setFontSize(10)
    doc.autoTable({
      startY: 50,
      body: data2,
      columns: columnsPDF.map(
        columna =>
          !headersNoPrint.includes(columna.header.toUpperCase()) && {
            header: columna.header,
            dataKey: columna.field
          }
      ),
      styles: { fontSize: fontSize },
      headStyles: { fillColor: [186, 0, 0] }
    })
    doc.save(nombrePDf + '.pdf')
  }

  const formatearValue = (value: any, key: string) => {
    if (ignoreFormat.includes(key)) return value + ''
    if (typeof value === 'string') {
      value = value
        .replace(/\$/g, '')
        .replace(/\./g, noPuntos ? '.' : '')//agregue esa validacion para que no elimine las comas en los lugares que asi lo requieren
        .replace(/,/g, noPuntos ? ',' : '.')
      if (!esNumero(value.trim())) return value
      if (value.includes('/') || value.includes(':')) return value
      return parseFloat(value)
    }
    return value
  }

  const reemplazarComasYdolar = (value: any) => {
    value = value
      .replace(/\$/g, '')
      .replace(/\./g, noPuntos ? '.' : '')//agregue esa validacion para que no elimine las comas en los lugares que asi lo requieren
      .replace(/,/g, noPuntos ? ',' : '.')
    if (!esNumero(value.trim())) return value
    if (value.includes('/') || value.includes(':')) return value
    return parseFloat(value)
  }

  const formatearPrecios = (value: any) => {
    if (typeof value === 'string') {
      if (noPuntos && value.includes('$')) {
        value = Number(value.trim().replace(/\$/g, '').replace(/,/g, ''))
      } else {
        value = reemplazarComasYdolar(value)
      }
    }
    if (value == false) return ''
    return value
  }

  const formatearExcel = (datos: any) => {
    if (datos) {
      return datos.map((data: any) => {
        return Object.entries(data).reduce((obj: any, [key, value]) => {
          obj[key] = noPuntos && !ignoreFormat.includes(key) ? formatearPrecios(value) : formatearValue(value, key)
          return obj
        }, {})
      })
    } else {
      return []
    }
  }

  const deleteColumns = ['acciones', 'ventas']
  const exportExcel = () => {
    const excelColumns = props.columnsPDF ? props.columnsPDF : props.columns
    //Filtro de acciones para que al exportar el documento tenga en cuenta no exportar tambien la columna y no se rompa la web
    const dataColumns = excelColumns.map(col => col.field).filter(x => !deleteColumns.includes(x))
    const dataColumnsObj = Object.fromEntries(excelColumns.map(col => [col.field, 0 ]))
    // para mi dentro de 1 mes esto es para obtener solo las columnas que se ven en la vista
    const objData = data.map(dat =>
      Object.fromEntries(
        Object.entries(Object.assign(dataColumnsObj, dat)).filter(entrie => dataColumns.includes(entrie[0]))
      )
    )
    const { usuario } = Storage.getState()
    const worksheet = xlsx.utils.json_to_sheet(
      !esDominioFuelly(usuario.dominio) ? formatearExcel(hasTag ? snakeCaseToSeparatedUppercase(objData) : snakeCaseToSeparatedUppercase2(objData)) : objData
    )

    // Este verifica segun los parametros que reciba las filas que tengan codigo de barras y les hace un formato para que no se muestre la notación cientifica.
    if (haveFormat.length > 0) {
      for (let s in worksheet) {
        for (const key in haveFormat) {
          if (s.toString()[0] === haveFormat[key] && worksheet[s].t === 'n') {
            worksheet[s].z = '0';
            worksheet[s].t = 's';
          }
        }
      }
    }

    const workbook = { Sheets: { [worksheetName]: worksheet }, SheetNames: [worksheetName] }
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',

    })

      saveAsExcelFile(excelBuffer, nombreExcel)

  }

  const saveAsExcelFile = (buffer: Buffer, fileName: string): void => {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      let EXCEL_EXTENSION = '.xlsx'
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      })
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
    })
  }

  const Header = () => {
    return (
 headerRigth ?   <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
 <div  style={{ textAlign: 'left', maxWidth:'10%' }}>
 {extra2 && extra2}
  </div>
    <div style={{ textAlign: 'right' }}>
      <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
        <InputText
          type="search"
          data-testid='buscar-table'
          onInput={(e: any) => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
          width="20%"
          data-cy={`btn-search-table-${idCy}`}
        />
        {extra && extra}
        {noExport
          ? null
          : [
             !noExcel && <button
              key="1"
              className="btn"
              id="exportExcel"
              onClick={exportExcel}
              style={{ marginLeft: '15px' , backgroundColor : 'white', border: '1px solid lightgray', boxShadow: 'none'}}
            >
              <img src={excelIcon} alt="excelIcon" />
            </button>,
            !noPdf && <button
              key="2"
              className="btn "
              id="exportPdf"
              onClick={exportPdf}
              style={{ marginLeft: '15px' , backgroundColor : 'white', border: '1px solid lightgray', boxShadow: 'none'}}
            >
              <img src={PdfIcon} alt="PdfIcon" />
            </button>
          ]}
        {
          !noRefresh ?
            <Button aria-label='refresh-table' icon="pi pi-refresh" onClick={() => actualizar()} style={{ float: 'right' }} />
            :
            null
        }
      </div>
    </div>
 </div> :  <div style={{ textAlign: 'left' }}>
      <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
        <InputText
          type="search"


          onInput={(e: any) => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
          width="20%"
          data-cy={`btn-search-table-${idCy}`}
        />
        {extra && extra}
        {noExport
          ? null
          : [
             !noExcel && <button
              key="1"
              className="btn"
              id="exportExcel"
              onClick={exportExcel}
              style={{ marginLeft: '15px' , backgroundColor : 'white', border: '1px solid lightgray', boxShadow: 'none'}}
            >
              <img src={excelIcon} alt="excelIcon" />
            </button>,
            !noPdf && <button
              key="2"
              className="btn "
              id="exportPdf"
              onClick={exportPdf}
              style={{ marginLeft: '15px' , backgroundColor : 'white', border: '1px solid lightgray', boxShadow: 'none'}}
            >
              <img src={PdfIcon} alt="PdfIcon" />
            </button>
          ]}
        {
          !noRefresh ?
            <Button icon="pi pi-refresh" onClick={() => actualizar()} style={{ float: 'right' }} />
            :
            null
        }
      </div>
    </div>
    )
  }

  const agregarSelect = (dato: any) => {
    Storage.dispatch({
      type: 'DATOS_SELECCIONADOS',
      seleccionados: dato
    })
  }







  return (
    <div className={`mx-auto ${className}`} style={{ overflow: 'auto' }}>
      <div className="content-section implementation">
        {/* @ts-ignore */}
        <PRDatatable
          id="htmlTableId"
          className="mx-auto"
          value={data}
          paginator={true}
          rows={rows}
          
          header={Header()}
          globalFilter={globalFilter}
          sortMode="multiple"
          emptyMessage="No se encontro"
          rowsPerPageOptions={[5, 10, 20, 50]}
          removableSort={true}
          responsive={true}
          autoLayout
          selection={Storage.getState().seleccionados}
          onSelectionChange={e => agregarSelect(e.value)}
        /* rowReorderIcon="pi pi-bars"
      rowReorder={true} */
        >
          {props.select && <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>}
          {columns != null
            ? columns.map((col, i) => {
              return (
                <Column
                  key={i}
                  field={col.field}
                  header={col.header}
                  style={col.style ? col.style : {'width': '300px'}}
                  sortable={true}
                  filterPlaceholder="Buscar..."
                  frozen={col.frozen}
                />
              )
            })
            : null}
        </PRDatatable>
      </div>
    </div>
  )
}

export default DataTableFilterDemo
