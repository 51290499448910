import React from 'react'
import Indicador from '../../componentes/indicadores'
export const ShowIndicador = ({ indicadores,ventasCanastillaImg,server,dominio,url }: any) => {
    
    return (
        <>
            {(+indicadores.total_cantidad_canastilla > 0 ||
                +indicadores.total_valor_canastilla > 0) && (
                    <Indicador color="#FFC700" bg_color="#FFEED4">
                        {/* header */}
                        <Indicador.Header
                            icon={`${server.hostMultimedia}/${dominio}/indicadores/ventasCanastillas.png`}
                            titulo={`${url.indexOf('cdl') > -1 ? 'VENTAS CDL' : 'VENTAS CANASTILLA'}`}
                            imgDefault={ventasCanastillaImg}
                        />
                        {/* Body */}
                        <Indicador.Body
                            subtitulo="VENTAS REALIZADAS"
                            datoArriba={indicadores.total_cantidad_canastilla}
                        />
                        {/* FOOTER */}
                        <Indicador.Footer
                            title="Total de ventas"
                            value={indicadores.total_valor_canastilla ?? 0}
                            style={{ textAlign: 'center' }}
                        />
                    </Indicador>
                )}
        </>
    )
}
