import React from 'react'
import LOGO from '../../assets/images/logo_terpel.jpg'


const PreLoaderTerpel = () => {
  //Check
    return (
        <div style={{ width: '100%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <img src={LOGO} className='animated flash' style={{ alignSelf: 'center', maxWidth: '300px' }} />

        </div>
    )
}

export default PreLoaderTerpel
