import React, { useState, useEffect, useContext } from 'react'
import Storage from 'util/store/Storage'
import { getSelectEDS } from 'app/vistas/Reportes/servicios/serviciosSelect'
import { Select } from 'components/ui/Select'
import './index.css'
import { EmpresasContext } from '../../context/DashBoardContext'
import { objetoVacio } from 'util/utils'

const FilterEntiti = (): React.ReactElement => {
  const [empresaSelect, setEmpresaSelect] = useState(null as any)
  const [empresaAll, setEmpresaAll] = useState([] as any)
  const { empresasHijos } = Storage.getState()
  const { setEmpresa } = useContext(EmpresasContext)

  useEffect(() => {
    obtenerData()
  }, [])

  const obtenerData = async (): Promise<void> => {
    try {
      const data = await getSelectEDS(empresasHijos)
      data.unshift({label:'TODOS',value:empresasHijos})
      setEmpresaAll(data)
    } catch (error) {
      console.error('TYPE ERROR: ' + error)
    }
  }
  const enviarDatos = () => {
    setEmpresa(objetoVacio(empresaSelect) ? empresasHijos : empresaSelect.value)
  }

  return (
    <div className="container_filtros">
      <h4>EMPRESA</h4>
      <Select
        id="filtros"
        label=""
        value={empresaSelect}
        onChange={value => setEmpresaSelect(value)}
        opciones={empresaAll}
        colMd={12}
      />
      <button className="btn btn-primary mt-3" style={{ width: '100%', padding: '5px' }} onClick={() => enviarDatos()}>
        BUSCAR
      </button>
    </div>
  )
}
export default FilterEntiti

